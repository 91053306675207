import "./Employees.css";
import React, { useEffect, useState, useContext } from "react";
import {
  PageHeader,
  Card,
  Form,
  Select,
  Input,
  Button,
  Table,
  Modal,
  Tag,
  Radio,
  Descriptions,
  InputNumber,
  Popconfirm,
  Dropdown,
  Menu,
  Col,
  Row,
} from "antd";
import {
  HomeOutlined,
  TeamOutlined,
  EditTwoTone,
  LockOutlined,
  UnlockOutlined,
  DeleteTwoTone,
  DownOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  getEmployees,
  getBranches,
  createEmployee,
  updateEmployee,
  activateEmployee,
  deactivateEmployee,
  getSubscriptions,
  generateLicenseInvoice,
  verifyPayment,
  deleteEmployee,
  machineUserToggle,
  getdevices,
  sendEmployeeToOdoo,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "../../../utils";
import {
  PERIOD_MONTHLY,
  PLAN_TYPE_PRO,
  USER_TYPE_BUSINESS_OWNER,
} from "../../../configs/constants";
import { AppContext } from "../../../context/AppContextProvider";
import HyperPayPaymentModal from "../../../components/HyperPayPaymentModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Employees = () => {
  const { authUser } = useContext(AppContext);
  const ADD_EMPLOYEE = "Add Employee";
  const { setFullPageLoading } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [purchaseLicenseModalVisible, setPurchaseLicenseModalVisible] =
    useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [validatePassword, setValidatePassword] = useState(true);
  const [modalTitle, setModalTitle] = useState(ADD_EMPLOYEE);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [userLicensePricing, setUserLicensePricing] = useState(false);
  const [freeTrialOver, setFreeTrialOver] = useState(false);
  const [usersCount, setUsersCount] = useState(1);
  const [branches, setBranches] = useState([]);
  const [employeesTableData, setEmployeesTableData] = useState([]);
  const [invoice, setInvoice] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [machineUserEmployee, setMachineUserEmployee] = useState([]);
  const [devicesData, setDevicesData] = useState([]);

  const [employeeForm] = Form.useForm();
  const [purchaseLicenseForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getEmployees()
      .then((response) => {
        setEmployeesTableData(
          response.data.data.owner.concat(response.data.data.employees)
        );
        getSubscriptions().then((response) => {
          setActiveSubscription(response.data.data.active_subscription);
          setUserLicensePricing(response.data.data.user_license_pricing);
          setFreeTrialOver(response.data.data.is_free_trial_over);
        });
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  useEffect(() => {
    setIsDataLoading(true);
  }, [reloadTableData]);

  useEffect(() => {
    if (window.location.search !== "") {
      let params = new URLSearchParams(window.location.search);
      if (params.get("id")) {
        setFullPageLoading(true);
        setTimeout(function () {
          verifyPayment({ checkoutID: params.get("id") })
            .then((response) => {
              if (
                response.data &&
                response.data &&
                response.data.result &&
                response.data.result.code
              ) {
                // Payment successfull
                if (response.data.result.code.split(".")[0] === "000") {
                  window.location =
                    window.location.pathname + "?payment_successful=1";
                } else {
                  window.location =
                    window.location.pathname + "?payment_successful=0";
                }
              }
            })
            .finally(() => {
              setFullPageLoading(false);
            });
        }, 1000);
      } else if (params.get("payment_successful")) {
        if (params.get("payment_successful") === "1") {
          NotificationWithIcon(
            "success",
            t("payment_successfull"),
            t("payment_successfull")
          );
        } else {
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            t("payment_not_successfull")
          );
        }
      }
    }
  }, [setFullPageLoading, t]);

  useEffect(() => {
    setIsDataLoading(true);
    getBranches().then((response) => {
      setBranches(response.data.data.branches);
    });
    getdevices()
      .then((response) => {
        setDevicesData(response.data.data.devices);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const showAddModal = () => {
    // Set Modal Title
    setModalTitle(t("employee_add"));

    // Reset Form Fields

    employeeForm.setFieldsValue({
      allow_editable_price: "0",
      can_refund_transaction: "0",
      is_machine_user: "0",
      can_add_product: "0",
      can_edit_product: "0",
    });

    // Show Modal
    setModalVisible(true);

    // Enable Password Validation
    setValidatePassword(true);
  };

  const handleAPIResponse = (response, type) => {
    let data = response.data || response.response.data;
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_EMPLOYEE) {
        NotificationWithIcon(
          "success",
          t("employee_added"),
          t("employee_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("employee_updated"),
          t("employee_updated_successfully")
        );
      }
    } else {
      // Error
      let errors = response.response.data.data.errors;
      if (errors && Object.keys(errors).length) {
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          errors[Object.keys(errors)[0]]
        );
      }
    }
    // Reset Form Fields
    employeeForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEditEmployee = () => {
    employeeForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);

        // Sending Request to API
        if (modalTitle === ADD_EMPLOYEE || modalTitle === t("employee_add")) {
          createEmployee(values)
            .then((response) => handleAPIResponse(response, ADD_EMPLOYEE))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateEmployee(values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.message
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (employee_id) => {
    // Find Employee from DataArray
    const employee = employeesTableData.find((emp) => emp.id === employee_id);

    // Set Modal Title
    setModalTitle(t("employee_edit") + " : " + employee.name);

    // Show Modal
    setModalVisible(true);
    // Set Form Fields
    employeeForm.setFieldsValue({
      id: employee.id,
      first_name: employee.first_name,
      last_name: employee.last_name,
      phone: employee.phone,
      branch_id: employee.branch_id,
      allow_editable_price: employee.allow_editable_price ? "1" : "0",
      can_refund_transaction: employee.can_refund_transaction ? "1" : "0",
      is_machine_user: employee.is_machine_user ? "1" : "0",
      can_add_product: employee.can_add_product ? "1" : "0",
      can_edit_product: employee.can_edit_product ? "1" : "0",
    });

    // Disable Password Validation
    setValidatePassword(false);
  };

  const handleCancel = () => {
    // Reset Form Fields
    employeeForm.resetFields();
    setModalVisible(false);
  };

  const handleDeleteEmployee = (id) => {
    deleteEmployee(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("employee_deleted"),
            t("employee_deleted_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        // Error
        console.log("Delete error: ", info.response.data.message);
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const passwordValueChanged = (e) => {
    if (
      e.target.value.length === 0 &&
      !(modalTitle === ADD_EMPLOYEE || modalTitle === t("employee_add"))
    ) {
      setValidatePassword(false);
    } else {
      setValidatePassword(true);
    }
  };

  const handleActivate = (employee_id) => {
    activateEmployee(employee_id).then((response) => {
      if (response.data.success === true) {
        setReloadTableData(!reloadTableData);
        NotificationWithIcon(
          "success",
          t("employee_activated"),
          t("employee_activated_successfully")
        );
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          response.data.message
        );
      }
    });
  };

  const handleDeactivate = (employee_id) => {
    deactivateEmployee(employee_id).then((response) => {
      if (response.data.success === true) {
        setReloadTableData(!reloadTableData);
        NotificationWithIcon(
          "success",
          t("employee_deactivated"),
          t("employee_deactivated_successfully")
        );
      } else {
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          response.data.message
        );
      }
    });
  };

  const showBuyMoreLicenseNotification = () => {
    // Error
    NotificationWithIcon(
      "error",
      t("buy_more_license"),
      t("buy_more_license_error")
    );
  };

  const showPurchaseLicenseModal = () => {
    setUsersCount(1);
    setPurchaseLicenseModalVisible(true);
  };

  const handlePurchaseLicenseCancel = () => {
    setPurchaseLicenseModalVisible(false);
  };

  const handlePurchaseLicense = () => {
    generateLicenseInvoice({ users_count: usersCount })
      .then((response) => {
        if (response.data.success === true) {
          setInvoice(response.data.data.invoice);
          setPaymentModalVisible(true);
          setReloadTableData(!reloadTableData);
          NotificationWithIcon(
            "success",
            t("user_licenses_added_successfully"),
            t("user_licenses_have_been_added_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            t(response.data.message)
          );
        }
      })
      .finally(() => {
        setPurchaseLicenseModalVisible(false);
      });
  };

  const calculatePerUserAmount = () => {
    if (
      activeSubscription.is_trial &&
      activeSubscription.type === PLAN_TYPE_PRO &&
      freeTrialOver === false
    ) {
      return 0;
    } else {
      if (activeSubscription.period === PERIOD_MONTHLY) {
        return (
          activeSubscription.license_amount -
          activeSubscription.license_discount
        );
      } else {
        // Calculate Yearly Amount
        let per_user_cost = 0;
        // if (activeSubscription.subscription_ending_in_days >= 330) {
        //   per_user_cost =
        //     activeSubscription.license_amount -
        //     activeSubscription.license_discount;
        // } else {
        // let months = Math.ceil(
        //   activeSubscription.subscription_ending_in_days / 31
        // );
        per_user_cost =
          userLicensePricing.license_amount -
          userLicensePricing.license_discount;
        //  * months
        // }
        return per_user_cost;
      }
    }
  };

  const handleMachineUserToggle = (e) => {
    machineUserToggle(machineUserEmployee).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        setReloadTableData(!reloadTableData);
        NotificationWithIcon(
          "success",
          t("employee_updated"),
          t("employee_updated_successfully")
        );
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data.message);
      }
    });
  };

  const handleSendToOdoo = (id) => {
    sendEmployeeToOdoo(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("send_to_odoo"),
            t("item_send_to_odoo_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
      });
  };

  const menuYes = (
    <Menu>
      <Menu.Item onClick={handleMachineUserToggle} key={0}>
        No
      </Menu.Item>
    </Menu>
  );
  const menuNo = (
    <Menu>
      <Menu.Item onClick={handleMachineUserToggle} key={1}>
        Yes
      </Menu.Item>
    </Menu>
  );

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "employees",
      breadcrumbName: t("menu_employees"),
      icon: <TeamOutlined />,
    },
  ];

  const detailsPartColumns = [
    {
      title: "#",
      key: "index",
      width: "5%",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    {
      title: t("phone_number"),
      dataIndex: "phone",
    },
    {
      title: t("branch"),
      align: "center",
      dataIndex: "branch_name",
    },
    {
      title: t("col_type"),
      align: "center",
      dataIndex: "type",
      render: (type) => {
        return type === USER_TYPE_BUSINESS_OWNER ? (
          <Tag color="green">{t("owner")}</Tag>
        ) : (
          <Tag color="blue">{t("employee")}</Tag>
        );
      },
    },
    {
      title: t("col_status"),
      align: "center",
      render: (employee) => {
        return (
          <>
            {employee.is_active ? (
              <Button
                type="link"
                onClick={() =>
                  employee.type !== USER_TYPE_BUSINESS_OWNER &&
                  handleDeactivate(employee.id)
                }
                style={{ fontSize: "18px", color: "#087c08" }}
              >
                <UnlockOutlined title={t("active")} />
              </Button>
            ) : (
              <Button
                type="link"
                onClick={() =>
                  employee.type !== USER_TYPE_BUSINESS_OWNER &&
                  handleActivate(employee.id)
                }
                style={{ fontSize: "18px", color: "#ff0000" }}
              >
                <LockOutlined title={t("inactive")} />
              </Button>
            )}
          </>
        );
      },
    },
    {
      title: t("is_machine_user"),
      render: (employee) => {
        return (
          <>
            {employee.type !== USER_TYPE_BUSINESS_OWNER &&
            devicesData.length !== 0 ? (
              <div>
                {employee.is_machine_user ? (
                  <Dropdown
                    overlay={menuYes}
                    trigger={["click"]}
                    onClick={() => setMachineUserEmployee(employee.id)}
                  >
                    <Button className="btn-success">
                      {t("yes")}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                ) : (
                  <Dropdown
                    overlay={menuNo}
                    trigger={["click"]}
                    onClick={() => setMachineUserEmployee(employee.id)}
                  >
                    <Button danger>
                      {t("no")}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                )}
              </div>
            ) : employee.is_machine_user ? (
              <Button className="btn-success">{t("yes")}</Button>
            ) : (
              <Button danger>{t("no")}</Button>
            )}
          </>
        );
      },
    },
    {
      title: t("col_allow_refund"),
      align: "center",
      dataIndex: "can_refund_transaction",
      render: (can_refund_transaction) => {
        return can_refund_transaction ? (
          <Tag color="green">{t("yes")}</Tag>
        ) : (
          <Tag color="red">{t("no")}</Tag>
        );
      },
    },
    {
      title: t("col_allow_add_products"),
      align: "center",
      dataIndex: "can_add_product",
      render: (can_add_product) => {
        return can_add_product ? (
          <Tag color="green">{t("yes")}</Tag>
        ) : (
          <Tag color="red">{t("no")}</Tag>
        );
      },
    },
    {
      title: t("col_allow_edit_products"),
      align: "center",
      dataIndex: "can_edit_product",
      render: (can_edit_product) => {
        return can_edit_product ? (
          <Tag color="green">{t("yes")}</Tag>
        ) : (
          <Tag color="red">{t("no")}</Tag>
        );
      },
    },
  ];

  const odooStatusColumn = [
    {
      title: t("col_odoo_status"),
      align: "center",
      render: (record) => {
        return (
          <>
            {record.odoo_reference_id ? (
              <CheckOutlined style={{ fontSize: "22px", color: "#52c41a" }} />
            ) : (
              <>
                <CloseOutlined
                  style={{
                    fontSize: "22px",
                    color: "#ff0000",
                    display: "block",
                    paddingBottom: "5px",
                  }}
                />
                <Button
                  type="primary"
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={() => handleSendToOdoo(record.id)}
                >
                  {t("send_to_odoo")}
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  const actionPartColumn = [
    {
      title: t("col_action"),
      align: "center",
      width: "100px",
      render: (employee) => {
        return (
          <>
            {employee.type !== USER_TYPE_BUSINESS_OWNER && (
              <>
                <Button
                  type="link"
                  onClick={() => handleEditModal(employee.id)}
                  title={t("employee_edit")}
                  style={{ padding: 6, fontSize: "18px" }}
                >
                  <EditTwoTone twoToneColor="#1890ff" />
                </Button>
                <Popconfirm
                  title={t("warning_delete")}
                  onConfirm={() => handleDeleteEmployee(employee.id)}
                  okText={t("yes")}
                  cancelText={t("no")}
                >
                  <DeleteTwoTone
                    style={{ padding: 6, fontSize: "18px" }}
                    twoToneColor="#ff0000"
                    title={t("delete_entity")}
                  />
                </Popconfirm>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_employees")}
        ghost={false}
        extra={[
          activeSubscription && (
            <h3 key={1}>
              Licenses Used {activeSubscription.used_users_count} out of{" "}
              {activeSubscription
                ? activeSubscription.user_licenses_count
                : "N/A"}
            </h3>
          ),
          activeSubscription &&
            activeSubscription.used_users_count !==
              activeSubscription.user_licenses_count && (
              <Button
                key="open-add-employee-modal"
                // type="primary"
                className="btn-success"
                onClick={
                  activeSubscription.used_users_count <
                  activeSubscription.user_licenses_count
                    ? showAddModal
                    : showBuyMoreLicenseNotification
                }
              >
                + {t("employee_add")}
              </Button>
            ),
          activeSubscription && (
            <Button
              key="open-purchase-license-modal"
              type="primary"
              onClick={showPurchaseLicenseModal}
            >
              +{" "}
              {activeSubscription.is_trial &&
              activeSubscription.type === PLAN_TYPE_PRO &&
              freeTrialOver === false
                ? t("add_free_license")
                : t("purchase_license")}
            </Button>
          ),
        ]}
      />
      <Card>
        <Table
          columns={
            authUser.external_integrations.length
              ? [
                  ...detailsPartColumns,
                  ...odooStatusColumn,
                  ...actionPartColumn,
                ]
              : [...detailsPartColumns, ...actionPartColumn]
          }
          dataSource={employeesTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      {/* Add Edit Employee Modal */}
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onOk={handleAddEditEmployee}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEditEmployee}
          >
            {modalTitle === ADD_EMPLOYEE || modalTitle === t("employee_add")
              ? t("employee_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-employee-form"
          layout="vertical"
          onFinish={handleAddEditEmployee}
          style={{ width: "100%", margin: "0" }}
          form={employeeForm}
        >
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 20,
              lg: 20,
              xl: 20,
              xxl: 20,
            }}
          >
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="first_name"
                label={t("first_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_name_required"),
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: t("validate_name_min_characters"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="last_name"
                label={t("last_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_name_required"),
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: t("validate_name_min_characters"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              {" "}
              <Form.Item
                name="phone"
                label={t("phone_number") + " eg: +966 5xxxxxxx"}
                rules={[
                  {
                    required: true,
                    message: t("validate_phone_required"),
                  },
                  {
                    min: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                  {
                    max: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                ]}
              >
                <PhoneInput
                  localization={["sa"]}
                  masks={{ sa: "(...) ..-..-.." }}
                  placeholder="+966 (123) 45-67-89"
                  country={"sa"}
                  onlyCountries={["sa"]}
                  style={{
                    width: "100%",
                    direction: "ltr",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="branch_id"
                label={t("branch")}
                rules={[
                  {
                    required: true,
                    message: t("validate_branch_required"),
                  },
                ]}
                hasFeedback
              >
                <Select
                  options={branches.map((type) => {
                    return {
                      label: type.name,
                      value: type.id,
                    };
                  })}
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="password"
                label={t("password")}
                rules={
                  validatePassword && [
                    {
                      required: true,
                      message: t("validate_password_required"),
                    },
                    {
                      min: 4,
                      message: t("validate_password_min_4_characters"),
                    },
                  ]
                }
                hasFeedback
              >
                <Input.Password onChange={passwordValueChanged} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="password_confirmation"
                label={t("confirm_password")}
                dependencies={["password"]}
                hasFeedback
                rules={
                  validatePassword && [
                    {
                      required: true,
                      message: t("validate_password_confirm_not_matched"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("validate_password_confirm_not_matched"))
                        );
                      },
                    }),
                  ]
                }
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="allow_editable_price"
                label={t("allow_editable_price")}
              >
                <Radio.Group buttonStyle="solid" key="allow-editable-price">
                  <Radio.Button key="allow-editable-price-0" value="0">
                    {t("no")}
                  </Radio.Button>
                  <Radio.Button key="allow-editable-price-1" value="1">
                    {t("yes")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="can_refund_transaction"
                label={t("user_allow_refund")}
              >
                <Radio.Group buttonStyle="solid" key="allow-refund">
                  <Radio.Button key="allow-refund-0" value="0">
                    {t("no")}
                  </Radio.Button>
                  <Radio.Button key="allow-refund-1" value="1">
                    {t("yes")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="can_add_product"
                label={t("user_allow_add_products")}
              >
                <Radio.Group buttonStyle="solid" key="allow-add-product">
                  <Radio.Button key="allow-add-product-0" value="0">
                    {t("no")}
                  </Radio.Button>
                  <Radio.Button key="allow-add-product-1" value="1">
                    {t("yes")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="can_edit_product"
                label={t("user_allow_edit_products")}
              >
                <Radio.Group buttonStyle="solid" key="allow-edit-product">
                  <Radio.Button key="allow-edit-product-0" value="0">
                    {t("no")}
                  </Radio.Button>
                  <Radio.Button key="allow-edit-product-1" value="1">
                    {t("yes")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {activeSubscription &&
            activeSubscription.is_trial &&
            activeSubscription.type === PLAN_TYPE_PRO &&
            activeSubscription.validity_days > 90 && (
              <Form.Item name="is_machine_user" label={t("is_machine_user")}>
                <Radio.Group buttonStyle="solid" key="is-machine-user">
                  <Radio.Button key="is-machine-user-0" value="0">
                    {t("no")}
                  </Radio.Button>
                  <Radio.Button key="is-machine-user-1" value="1">
                    {t("yes")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            )}
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
      {/* user purchase License Modal */}
      {activeSubscription && (
        <Modal
          visible={purchaseLicenseModalVisible}
          title={t("Purchase License")}
          onOk={handlePurchaseLicense}
          onCancel={handlePurchaseLicenseCancel}
          footer={[
            <Button
              key="back"
              loading={loading}
              onClick={handlePurchaseLicenseCancel}
            >
              {t("cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handlePurchaseLicense}
            >
              {t("purchase")}
            </Button>,
          ]}
        >
          <Form
            name="purchase-license-form"
            layout="vertical"
            onFinish={handlePurchaseLicense}
            style={{ width: "100%", margin: "0" }}
            form={purchaseLicenseForm}
          >
            <h4>{t("calculations")}:</h4>

            <Descriptions
              bordered
              size="middle"
              column={{ xs: 8, sm: 16, md: 24 }}
              layout="horizontal"
            >
              <Descriptions.Item
                span={24}
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>
                      <InputNumber
                        style={{ width: "60px" }}
                        min={1}
                        onChange={(value) => setUsersCount(value)}
                        value={usersCount}
                      />{" "}
                      {t("licenses")}
                    </span>
                    <span>
                      {currencyFormatter.format(calculatePerUserAmount())} /
                      {t("license")}
                    </span>
                  </div>
                }
                contentStyle={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: "8px 12px",
                }}
                labelStyle={{ padding: "8px 12px" }}
              >
                {currencyFormatter.format(
                  calculatePerUserAmount() * usersCount
                )}
              </Descriptions.Item>
            </Descriptions>
          </Form>
        </Modal>
      )}
      {invoice && (
        <HyperPayPaymentModal
          invoiceId={invoice.id}
          amount={invoice.amount_charged}
          paymentModalVisible={paymentModalVisible}
          setPaymentModalVisible={setPaymentModalVisible}
        />
      )}
    </>
  );
};

export default Employees;
