import "./Helpdesk.css";
import React, { useEffect, useState } from "react";
import { PageHeader, Form, Input, Button, Modal, Card, Row, Col } from "antd";
import { HomeOutlined, CustomerServiceOutlined } from "@ant-design/icons";
import { createTicket, getTicketsCount } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import ImageUpload from "../../../components/ImageUpload";
import NewTickets from "../../../components/NewTickets";
import InprogressTickets from "../../../components/InprogressTickets";
import DoneTickets from "../../../components/DoneTickets";
import ClosedTickets from "../../../components/ClosedTickets";

const Helpdesk = () => {
  const ADD_TICKET = "Add Ticket";
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const [reload, setReload] = useState(false);
  const [ticketsCount, setTicketsCount] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [modalTitle, setModalTitle] = useState(ADD_TICKET);
  const [image, setImage] = useState(false);
  const [addTicketForm] = Form.useForm();
  const { t } = useTranslation();
  const { TextArea } = Input;

  useEffect(() => {
    getTicketsCount().then((response) => {
      setTicketsCount(response.data.data);
    });
  }, [reload]);

  const showModal = () => {
    // Set Modal Title
    setModalTitle(t("ticket_add"));
    // Show Modal
    setModalVisible(true);
    // Reset Form Fields
    addTicketForm.resetFields();
    // Reset Image Field
    setImage(false);
  };

  const handleAPIResponse = (response, type) => {
    if (response.data.success === true) {
      setReload(!reload);
      // Success
      if (type === ADD_TICKET) {
        NotificationWithIcon(
          "success",
          t("ticket_added"),
          t("ticket_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("ticket_updated"),
          t("ticket_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        response.data.message
      );
    }
    // Reset Form Fields
    addTicketForm.resetFields();
    // Reset Image Field
    setImage(false);
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    addTicketForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Set Form values as FormData
        console.log(image);
        const formData = new window.FormData();
        if (typeof image == "object" && image) {
          formData.append("attachment", image);
        }
        formData.append("description", values.description);

        // Sending Request to API
        if (modalTitle === ADD_TICKET || modalTitle === t("ticket_add")) {
          createTicket(formData)
            .then((response) => handleAPIResponse(response, ADD_TICKET))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
        //  else {
        //   updateBranch(values)
        //     .then((response) => handleAPIResponse(response, null))
        //     .catch((info) => {
        //       console.log("API Failed:", info.response);
        //       // Error
        //       NotificationWithIcon(
        //         "error",
        //         t("something_went_wrong"),
        //         info.response.data.data.errors.name
        //       );
        //     })
        //     .finally(() => {
        //       // Set Loading False
        //       setLoading(false);
        //     });
        // }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    addTicketForm.resetFields();
    // Reset Image Field
    setImage(false);
    // Hide Modal
    setModalVisible(false);
  };

  const onChangeTab = (key) => {
    setTickets(key);
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "helpdesk",
      breadcrumbName: t("menu_help_desk"),
      icon: <CustomerServiceOutlined />,
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_help_desk")}
        ghost={false}
        extra={[
          <Button key="open-helpdesk-modal" type="primary" onClick={showModal}>
            + {t("ticket_add")}
          </Button>,
        ]}
      />

      <Row
        gutter={[20, 20]}
        style={{ marginBottom: "20px", textAlign: "center" }}
      >
        <Col span={6}>
          <Card
            className={
              "tab_title" + (tickets === "new_tickets" ? " active" : "")
            }
            onClick={() => onChangeTab("new_tickets")}
          >
            <div>
              {t("col_new")}
              <span className="tab_count">
                {ticketsCount.new_tickets_count}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className={
              "tab_title" + (tickets === "inprogress_tickets" ? " active" : "")
            }
            onClick={() => onChangeTab("inprogress_tickets")}
          >
            <div>
              {t("col_inprogress")}
              <span className="tab_count">
                {ticketsCount.in_progress_tickets_count}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className={
              "tab_title" + (tickets === "done_tickets" ? " active" : "")
            }
            onClick={() => onChangeTab("done_tickets")}
          >
            <div>
              {t("col_done")}
              <span className="tab_count">
                {ticketsCount.done_tickets_count}
              </span>
            </div>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            className={
              "tab_title" + (tickets === "closed_tickets" ? " active" : "")
            }
            onClick={() => onChangeTab("closed_tickets")}
          >
            <div>
              {t("col_closed")}{" "}
              <span className="tab_count">
                {ticketsCount.closed_tickets_count}
              </span>
            </div>
          </Card>
        </Col>
      </Row>

      {tickets === "new_tickets" && <NewTickets />}
      {tickets === "inprogress_tickets" && <InprogressTickets />}
      {tickets === "done_tickets" && <DoneTickets />}
      {tickets === "closed_tickets" && <ClosedTickets />}

      {/* <Spin spinning={isDataLoading}>
        <Row gutter={20}>
          <Col span={6}>
            <Card
              style={{ backgroundColor: "#fafafa" }}
              title={t("col_new")}
              extra={ticketsTableData.new_tickets_count}
              bordered={false}
            >
              {ticketsTableData.new_tickets_count ? (
                ticketsTableData.new_tickets.map((ticket) => {
                  return (
                    <Card.Grid
                      style={{ width: "100%", backgroundColor: "#fff" }}
                    >
                      {ticket.description}
                    </Card.Grid>
                  );
                })
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
          <Col span={6}>
            <Card
              style={{ backgroundColor: "#fafafa" }}
              title={t("col_inprogress")}
              extra={ticketsTableData.in_progress_tickets_count}
              bordered={false}
            >
              {ticketsTableData.in_progress_tickets_count ? (
                ticketsTableData.in_progress_tickets.map((ticket) => {
                  return (
                    <Card.Grid
                      style={{ width: "100%", backgroundColor: "#fff" }}
                    >
                      {ticket.description}
                    </Card.Grid>
                  );
                })
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
          <Col span={6}>
            <Card
              style={{ backgroundColor: "#fafafa" }}
              title={t("col_done")}
              extra={ticketsTableData.done_tickets_count}
              bordered={false}
            >
              {ticketsTableData.done_tickets_count ? (
                ticketsTableData.done_tickets.map((ticket) => {
                  return (
                    <Card.Grid
                      style={{ width: "100%", backgroundColor: "#fff" }}
                    >
                      {ticket.description}
                    </Card.Grid>
                  );
                })
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
          <Col span={6}>
            <Card
              style={{ backgroundColor: "#fafafa" }}
              title={t("col_closed")}
              extra={ticketsTableData.closed_tickets_count}
              bordered={false}
            >
              {ticketsTableData.closed_tickets_count ? (
                ticketsTableData.col_closed.map((ticket) => {
                  return (
                    <Card.Grid
                      style={{ width: "100%", backgroundColor: "#fff" }}
                    >
                      {ticket.description}
                    </Card.Grid>
                  );
                })
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
        </Row>
      </Spin> */}

      <Modal
        visible={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_TICKET || modalTitle === t("ticket_add")
              ? t("ticket_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-helpdesk-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={addTicketForm}
        >
          <Form.Item
            name="description"
            label={t("description")}
            rules={[
              {
                required: true,
                message: t("validate_description_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <TextArea rows={4} />
          </Form.Item>

          <div className="ant-col ant-form-item-label">
            <label title={t("attachment")}>{t("attachment")}</label>
          </div>
          <ImageUpload
            name="product_image"
            callbackSetImageFile={setImage}
            image={image}
          />

          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Helpdesk;
