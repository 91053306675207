import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import {
  PageHeader,
  Card,
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
  Modal,
  Checkbox,
  Radio,
  InputNumber,
  Typography,
  Space,
} from "antd";
import {
  HomeOutlined,
  SettingOutlined,
  LoadingOutlined,
  UploadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  updateAuthDetails,
  getRegions,
  getCities,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import {
  COMPANY_STATUS_KYC,
  SIDEBAR_MENU_KEY_HOME,
} from "../../../configs/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const { Text } = Typography;

const StoreConfiguration = () => {
  const {
    authUser,
    setAuthUser,
    setFullPageLoading,
    appConfig,
    setActiveMenu,
  } = useContext(AppContext);
  const [declarationModalVisible, setDeclarationModalVisible] = useState(false);
  const [acceptCheckbox, setAcceptCheckbox] = useState(false);
  const [logoUploading, setLogoUploading] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [crCertificateUploading, setCRCertificateUploading] = useState(false);
  const [crCertificateUrl, setCRCertificateUrl] = useState("");
  const [vatCertificateUploading, setVATCertificateUploading] = useState(false);
  const [vatCertificateUrl, setVATCertificateUrl] = useState("");
  const [hideFormField, setHideFormField] = useState(true);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [sameBillingAddress, setSameBillingAddress] = useState(false);
  const [vatExempt, setVatExempt] = useState(false);
  const [addressField, setAddressField] = useState("");
  const formRef = React.createRef();
  const [KYCForm] = Form.useForm();
  const { t } = useTranslation();
  let history = useHistory();
  let region_id = authUser.billing_state?.id;

  const businessTypes = [
    "beauty_and_personal_care",
    "retail_food_drinks",
    "healthcare_and_fitness",
    "home_and_repair",
    "entertainment",
    "professional_services",
    "transportation",
  ];

  useEffect(() => {
    if (authUser.company_status === COMPANY_STATUS_KYC) {
      setHideFormField(false);
    }
  }, [authUser.company_status]);

  useEffect(() => {
    setLogoUrl(authUser.logo);
    setCRCertificateUrl(authUser.cr_certificate);
    setVATCertificateUrl(authUser.vat_certificate);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getRegions().then((response) => {
      setRegions(response.data.data.regions);
    });
  }, []);

  useEffect(() => {
    formRef.current.setFieldsValue({
      business_name: authUser.business_name,
      business_type: authUser.business_type,
      address: authUser.address,
      round_off: authUser.round_off,
      preferred_contact_time: authUser.preferred_contact_time,
      cr: authUser.cr,
      is_vat_exempt: authUser.is_vat_exempt,
      vat: authUser.vat,
      shop_address: authUser.address,
      is_billing_add_same_as_postal: authUser.is_billing_add_same_as_postal,
      billing_address: authUser.billing_address,
      billing_city_id: authUser.billing_city?.id,
      billing_state_id: authUser.billing_state?.id,
      billing_country: authUser.billing_country || "Saudi Arabia",
      billing_post_code: authUser.billing_post_code,
    });
    setVatExempt(authUser.is_vat_exempt);
    setSameBillingAddress(authUser.is_billing_add_same_as_postal);
    handleCityRequestOnLoad(region_id);
    // eslint-disable-next-line
  }, [authUser]);

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(t("validate_image_file_type"));
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(t("validate_image_size"));
    }
    return isJpgOrPng && isLt2M;
  }

  function beforeUploadCertificate(file) {
    console.log(file.type);
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      message.error(t("validate_image_pdf_file_type"));
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error(t("validate_image_size_10"));
    }
    return isJpgOrPngOrPdf && isLt10M;
  }

  const SameBillingAddress = (e) => {
    setSameBillingAddress(e.target.checked);
    e.target.checked
      ? KYCForm.setFieldsValue({ billing_address: addressField })
      : KYCForm.setFieldsValue({ billing_address: "" });
  };

  const handleChange = (info, file_name) => {
    if (info.file.status === "uploading" && file_name === "logo") {
      setLogoUploading(true);
      setLogoUrl("");
    } else if (
      info.file.status === "uploading" &&
      file_name === "cr_certificate"
    ) {
      setCRCertificateUploading(true);
      setCRCertificateUrl("");
    } else if (
      info.file.status === "uploading" &&
      file_name === "vat_certificate"
    ) {
      setVATCertificateUploading(true);
      setVATCertificateUrl("");
    }
    return;
  };

  const uploadCustomRequest = (data, file_name) => {
    const formData = new FormData();
    if (file_name === "logo") {
      formData.append("logo", data.file);
    } else if (file_name === "cr_certificate") {
      formData.append("cr_certificate", data.file);
    } else if (file_name === "vat_certificate") {
      formData.append("vat_certificate", data.file);
    }

    updateAuthDetails(formData).then(function (response) {
      if (file_name === "logo") {
        setLogoUploading(false);
      } else if (file_name === "cr_certificate") {
        setCRCertificateUploading(false);
      } else if (file_name === "vat_certificate") {
        setVATCertificateUploading(false);
      }

      if (response.data.success === true) {
        if (file_name === "logo") {
          setLogoUrl(response.data.data.user.logo);
        } else if (file_name === "cr_certificate") {
          setCRCertificateUrl(response.data.data.user.cr_certificate);
        } else if (file_name === "vat_certificate") {
          setVATCertificateUrl(response.data.data.user.vat_certificate);
        }
      }
    });
  };

  const uploadButton = (file_name) => (
    <div>
      {file_name === "logo" && logoUploading && <LoadingOutlined />}
      {file_name === "logo" && !logoUploading && <UploadOutlined />}
      {file_name === "cr_certificate" && crCertificateUploading && (
        <LoadingOutlined />
      )}
      {file_name === "cr_certificate" && !crCertificateUploading && (
        <UploadOutlined />
      )}
      {file_name === "vat_certificate" && vatCertificateUploading && (
        <LoadingOutlined />
      )}
      {file_name === "vat_certificate" && !vatCertificateUploading && (
        <UploadOutlined />
      )}

      <div style={{ marginTop: 8 }}>{t("upload")}</div>
    </div>
  );

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "store-configuration",
      breadcrumbName: t("menu_store_configuration"),
      icon: <SettingOutlined />,
    },
  ];

  const onFinishConfigForm = (formData) => {
    // if (!authUser.logo && !logoUrl) {
    //   message.error(t("validate_business_logo_required"));
    // } else
    if (!authUser.cr_certificate && !crCertificateUrl) {
      message.error(t("validate_cr_certificate_required"));
    }
    // else if (!authUser.vat_certificate && !vatCertificateUrl) {
    //   message.error(t("validate_vat_certificate_required"));
    // }
    else {
      setFullPageLoading(true);
      updateAuthDetails(formData)
        .then(function (response) {
          if (response.data && response.data.success === true) {
            setAuthUser(
              response.data.data.user
              // ...authUser,
              // ...formData,
              // ...{ logo: logoUrl },
              // ...{ logo: crCertificateUrl },
              // ...{ logo: vatCertificateUrl },
            );
            message.success(t("shop_configuration_successfully"));
          } else {
            let errorMessages = Object.values(response?.data?.data?.errors);
            errorMessages.forEach(function (key, value) {
              let temp = Object.values(key);
              message.error(temp[0]);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setFullPageLoading(false);
        });
    }
  };

  const validateFormFields = () => {
    formRef.current.validateFields().then((err) => {
      if (!authUser.cr_certificate && !crCertificateUrl) {
        message.error(t("validate_cr_certificate_required"));
      } else if (
        !vatExempt &&
        !authUser.vat_certificate &&
        !vatCertificateUrl
      ) {
        message.error(t("validate_vat_certificate_required"));
      } else {
        setAcceptCheckbox(false);
        setDeclarationModalVisible(true);
      }
    });
  };

  const handleConfirm = () => {
    if (!acceptCheckbox) {
      message.error("Kindly Check the declaration checkbox!");
    } else {
      setDeclarationModalVisible(false);
      formRef.current.submit();
    }
  };

  const handleCityRequestOnLoad = (value) => {
    if (value) {
      getCities(value).then((response) => {
        setCities(response.data.data.cities);
      });
    }
  };

  const handleRegionSelect = (value) => {
    KYCForm.resetFields(["billing_city_id"]);
    getCities(value).then((response) => {
      setCities(response.data.data.cities);
    });
  };

  const handleSkipDetails = () => {
    setActiveMenu(SIDEBAR_MENU_KEY_HOME);
    history.push("/home");
    updateAuthDetails().then((response) => {
      if (response.data && response.data.success === true) {
        setAuthUser(response.data.data.user);
      } else {
        let errorMessages = Object.values(response?.data?.data?.errors);
        errorMessages.forEach(function (key, value) {
          let temp = Object.values(key);
          message.error(temp[0]);
        });
      }
    });
  };

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_store_configuration")}
        ghost={false}
      />
      <Card>
        {(!authUser.business_name ||
          !authUser.business_type ||
          !authUser.cr ||
          !authUser.vat ||
          !authUser.address ||
          !authUser.logo) && (
          <h1>
            Hey {authUser.name}, {t("plz_fill_details")}:
          </h1>
        )}
        <Form
          name="store-configuration-form"
          layout="vertical"
          onFinish={onFinishConfigForm}
          style={{ width: "100%", margin: "20px auto 0" }}
          ref={formRef}
          form={KYCForm}
          scrollToFirstError
        >
          <Form.Item name="business_name" label={t("business_name")}>
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="business_type"
            label={t("business_type")}
            rules={[
              {
                required: true,
                message: t("validate_business_type_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Select disabled={hideFormField}>
              {businessTypes.map((business) => (
                <Select.Option key={business} value={t(business)}>
                  {t(business)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="address"
            label={t("address")}
            rules={[
              {
                required: true,
                message: t("validate_address"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input
              onChange={(e) => {
                setAddressField(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="cr"
            label={t("cr_number")}
            rules={[
              {
                required: true,
                message: t("validate_cr_number_required"),
              },
              {
                min: 10,
                message: t("validate_cr_number_exact_limit"),
              },
              {
                max: 10,
                message: t("validate_cr_number_exact_limit"),
              },
            ]}
            hasFeedback
          >
            <Input disabled={hideFormField} status="error" maxLength={10} />
          </Form.Item>
          <div className="ant-col ant-form-item-label">
            <label
              className="ant-form-item-required"
              title={t("cr_certificate")}
            >
              {t("cr_certificate")}
            </label>
            <Text type="warning"> ({t("max_upload_size_10mb_warning")})</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              width: "max-content",
              marginBottom: "10px",
            }}
          >
            {!hideFormField && (
              <Upload
                name="cr_certificate"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUploadCertificate}
                onChange={(info) => handleChange(info, "cr_certificate")}
                customRequest={(data) =>
                  uploadCustomRequest(data, "cr_certificate")
                }
              >
                {crCertificateUrl ? (
                  <div>
                    <UploadOutlined />
                    <div style={{ marginTop: 8 }}>{t("change")}</div>
                  </div>
                ) : (
                  uploadButton("cr_certificate")
                )}
              </Upload>
            )}
            {crCertificateUrl && (
              <Button type="primary" href={crCertificateUrl} target="_blank">
                <EyeOutlined /> {t("preview") + " " + t("cr_certificate")}
              </Button>
            )}
          </div>
          <Form.Item name="is_vat_exempt" valuePropName="checked">
            <Checkbox
              onChange={(e) => {
                setVatExempt(e.target.checked);
              }}
              disabled={hideFormField}
            >
              {t("is_vat_exempt")}
            </Checkbox>
          </Form.Item>
          {!vatExempt && (
            <>
              <Form.Item
                name="vat"
                label={t("vat_number")}
                rules={[
                  {
                    required: true,
                    message: t("validate_vat_number_required"),
                  },
                  {
                    min: 15,
                    message: t("validate_vat_number_exact_limit"),
                  },
                  {
                    max: 15,
                    message: t("validate_vat_number_exact_limit"),
                  },
                ]}
                hasFeedback
              >
                <Input disabled={hideFormField} maxLength={15} />
              </Form.Item>

              {/* <div style={{ marginBottom: "15px" }}>
              <Button
                type="link"
                size="small"
                href="/images/VAT-Exempt-latter-sample.jpg"
                target="_blank"
              >
                <EyeOutlined />{" "}
                {t("preview") + " " + t("vat_exempt_latter_sample")}
              </Button>
            </div> */}
            </>
          )}
          {!vatExempt && (
            <>
              <div className="ant-col ant-form-item-label">
                <label
                  className="ant-form-item-required"
                  title={
                    !vatExempt ? t("vat_certificate") : t("vat_exempt_latter")
                  }
                >
                  {!vatExempt ? t("vat_certificate") : t("vat_exempt_latter")}
                </label>
                <Text type="warning">
                  {" "}
                  ({t("max_upload_size_10mb_warning")})
                </Text>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  width: "max-content",
                  marginBottom: "10px",
                }}
              >
                {!hideFormField && (
                  <Upload
                    name="vat_certificate"
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={beforeUploadCertificate}
                    onChange={(info) => handleChange(info, "vat_certificate")}
                    customRequest={(data) =>
                      uploadCustomRequest(data, "vat_certificate")
                    }
                  >
                    {vatCertificateUrl ? (
                      <div>
                        <UploadOutlined />
                        <div style={{ marginTop: 8 }}>{t("change")}</div>
                      </div>
                    ) : (
                      uploadButton("vat_certificate")
                    )}
                  </Upload>
                )}
                {vatCertificateUrl && (
                  <Button
                    type="primary"
                    href={vatCertificateUrl}
                    target="_blank"
                  >
                    <EyeOutlined />
                    {!vatExempt
                      ? t("preview") + " " + t("vat_certificate")
                      : t("preview") + " " + t("vat_exempt_latter")}
                  </Button>
                )}
              </div>
            </>
          )}
          <div className="ant-col ant-form-item-label">
            <label title={t("business_logo")}>{t("business_logo")}</label>
            <Text type="warning"> ({t("max_upload_size_2mb_warning")})</Text>
          </div>
          <Upload
            name="logo"
            listType="picture-card"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={(info) => handleChange(info, "logo")}
            customRequest={(data) => uploadCustomRequest(data, "logo")}
          >
            {logoUrl ? (
              <img
                src={logoUrl}
                alt={t("business_logo")}
                style={{ width: "100%" }}
              />
            ) : (
              uploadButton("logo")
            )}
          </Upload>
          <br />
          <h2>{t("billing_details")}</h2>
          <Form.Item
            name="billing_country"
            label={t("billing_country")}
            rules={[
              {
                required: true,
                message: t("validate_billing_country_required"),
              },
            ]}
            hasFeedback
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="billing_post_code"
            label={t("billing_post_code")}
            rules={[
              {
                required: true,
                message: t("validate_billing_post_code_required"),
              },
              // {
              //   type: "integer",
              //   message: t("validate_billing_post_code_number"),
              // },
            ]}
            hasFeedback
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="billing_state_id"
            label={t("billing_state")}
            rules={[
              {
                required: true,
                message: t("validate_billing_state_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              onChange={handleRegionSelect}
              options={regions.map((region) => {
                return {
                  label:
                    appConfig.direction === "ltr"
                      ? region.name_en
                      : region.name_ar,
                  value: region.id,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            name="billing_city_id"
            label={t("billing_city")}
            rules={[
              {
                required: true,
                message: t("validate_billing_city_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              options={cities.map((city) => {
                return {
                  label:
                    appConfig.direction === "ltr" ? city.name_en : city.name_ar,
                  value: city.id,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item
            name="is_billing_add_same_as_postal"
            valuePropName="checked"
          >
            <Checkbox
              onChange={SameBillingAddress}
              // checked={sameBillingAddress}
            >
              {t("billing_address_same_as_shop_address")}
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="billing_address"
            label={t("billing_address")}
            rules={[
              {
                required: true,
                message: t("validate_billing_address_required"),
              },
            ]}
            hasFeedback
          >
            <Input disabled={sameBillingAddress} />
          </Form.Item>
          {hideFormField && (
            <>
              <Form.Item name="round_off" label={t("allow_round_off")}>
                <Radio.Group buttonStyle="solid" key="allow-round-off">
                  <Radio.Button key="allow-round-off-true" value={true}>
                    {t("yes")}
                  </Radio.Button>
                  <Radio.Button key="allow-round-off-false" value={false}>
                    {t("no")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="preferred_contact_time"
                label={t("preferred_contact_time")}
                rules={[
                  {
                    required: true,
                    message: t("validate_preferred_contact_time_required"),
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={"09:00 AM - 02:00 PM"}>
                    09:00 AM - 02:00 PM
                  </Radio>
                  <Radio value={"02:00 PM - 05:00 PM"}>
                    02:00 PM - 05:00 PM
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}
          <br />
          <Form.Item>
            <Space>
              {authUser.is_onboarding_complete !== 1 && (
                <Button onClick={handleSkipDetails}>{t("skip")}</Button>
              )}
              <Button type="primary" onClick={validateFormFields}>
                {t("submit")}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      <Modal
        title={t("declaration")}
        visible={declarationModalVisible}
        onCancel={() => setDeclarationModalVisible(false)}
        onOk={handleConfirm}
      >
        <Checkbox
          onChange={(e) => {
            setAcceptCheckbox(e.target.checked);
          }}
          defaultChecked={false}
          checked={acceptCheckbox}
        >
          {t("declaration_text")}
        </Checkbox>
      </Modal>
    </>
  );
};

export default StoreConfiguration;
