import React, { useEffect, useState } from "react";
import { PageHeader, Card, Table, Tag, Button } from "antd";
import {
  AccountBookOutlined,
  EyeOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { getinvoices } from "../../../network/network";
import {
  INVOICE_DETAIL_TYPE_SUBSCRIPTION,
  INVOICE_DETAIL_TYPE_LICENSE,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_UNPAID,
  INVOICE_DETAIL_TYPE_DEVICE_PAYMENT,
} from "../../../configs/constants";
import { currencyFormatter } from "../../../utils";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import HyperPayPaymentModal from "../../../components/HyperPayPaymentModal";

const Invoices = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [invoicesTableData, setinvoicesTableData] = useState([]);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);
  const [invoiceId, setInvoiceId] = useState(false);
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    // Set Loading True
    setIsDataLoading(true);
    getinvoices()
      .then((response) => {
        let invoices = response.data.data.invoices;
        // This will hold all of the invoices being modified
        let invoices_data = [];
        invoices.forEach((invoice) => {
          // details will hold all details being simplified
          let details = [];
          invoice.details.forEach((detail) => {
            if (details.some((e) => e.item === detail.item)) {
              var objIndex = details.findIndex(
                (obj) => obj.item === detail.item
              );
              details[objIndex].quantity =
                details[objIndex].quantity + detail.quantity;
            } else {
              details.push(detail);
            }
          });
          invoice.details = details;
          invoices_data.push(invoice);
        });
        setinvoicesTableData(invoices_data);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const showPaymentModal = async (invoice) => {
    if (invoice && invoice.amount_charged > 0) {
      setPaymentModalVisible(true);
      console.log(invoice);
      setInvoiceId(invoice.id);
      setInvoiceAmount(invoice.amount_charged);
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        t("something_went_wrong")
      );
    }
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "invoices",
      breadcrumbName: t("menu_invoices"),
      icon: <AccountBookOutlined />,
    },
  ];

  const invoicesTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("invoice_ID"),
      dataIndex: "uid",
    },
    {
      title: t("col_items"),
      render: (invoices) => {
        return (
          <>
            {invoices.details.map((invoice, i) => {
              return (
                <>
                  {invoice.type === INVOICE_DETAIL_TYPE_SUBSCRIPTION && (
                    <Tag color="green" key={`${invoice.id}-${i}`}>
                      {invoice.item}
                    </Tag>
                  )}

                  {invoice.type === INVOICE_DETAIL_TYPE_LICENSE && (
                    <Tag color="blue" key={`${invoice.id}-${i}`}>
                      {invoice.item} x {invoice.quantity}
                    </Tag>
                  )}

                  {invoice.type === INVOICE_DETAIL_TYPE_DEVICE_PAYMENT && (
                    <Tag color="orange" key={`${invoice.id}-${i}`}>
                      {invoice.item} x {invoice.quantity}
                    </Tag>
                  )}
                </>
              );
            })}
          </>
        );
      },
    },
    {
      title: t("total_amount_charged"),
      render: (invoices) => {
        return currencyFormatter.format(invoices.amount_charged);
      },
    },
    {
      title: t("col_status"),
      render: (invoices) => {
        return (
          <>
            {invoices.status === INVOICE_STATUS_PAID && (
              <Tag color="green">Paid</Tag>
            )}
            {invoices.status === INVOICE_STATUS_UNPAID && (
              <Tag color="red">Unpaid</Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("menu_invoices"),
      render: (invoice) => {
        return (
          <>
            {invoice.status === INVOICE_STATUS_UNPAID && (
              <Button
                type="primary"
                style={{
                  backgroundColor: "#2e7c08",
                  border: "#2e7c08",
                  marginBottom: "10px",
                  marginRight: "10px",
                }}
                size="small"
                onClick={() => showPaymentModal(invoice)}
              >
                {t("pay_now")}!
              </Button>
            )}
            <Button
              type="default"
              size="small"
              target="_blank"
              href={
                process.env.REACT_APP_API_ROOT +
                "/companies/" +
                invoice.company.id +
                "/invoices/" +
                invoice.id
              }
            >
              <EyeOutlined />
              {t("view_invoice")}
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_invoices")}
        ghost={false}
      />
      <Card>
        <Table
          columns={invoicesTableColumns}
          dataSource={invoicesTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      {invoiceId && (
        <HyperPayPaymentModal
          invoiceId={invoiceId}
          amount={invoiceAmount}
          paymentModalVisible={paymentModalVisible}
          setPaymentModalVisible={setPaymentModalVisible}
        />
      )}
    </>
  );
};

export default Invoices;
