import "./transactions.css";
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  PageHeader,
  Card,
  Table,
  Tag,
  Modal,
  Form,
  DatePicker,
  Input,
  Select,
  Button,
  Radio,
} from "antd";
import {
  HomeOutlined,
  DollarOutlined,
  EyeTwoTone,
  CloudDownloadOutlined,
  UndoOutlined,
  MinusCircleFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  getTransactions,
  refundTransaction,
  sendTransactionToOdoo,
  downloadExcelReport,
  getBranches,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { AppContext } from "../../../context/AppContextProvider";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import {
  TRANSACTION_TYPE_CASH,
  // TRANSACTION_TYPE_MADA,
  // TRANSACTION_TYPE_STC,
  transactionTypes,
  TAX_PERCENTAGE,
  REFUND_TYPE_FULL,
  REFUND_TYPE_PARTIAL,
  USER_TYPE_BUSINESS_OWNER,
  TRANSACTION_TYPE_CREDIT,
  TRANSACTION_TYPE_MULTI,
} from "../../../configs/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import TransactionSlip from "../../../components/TransactionSlip/TransactionSlip";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import ReactToPrint from "react-to-print";

const Transactions = () => {
  const { authUser, appConfig } = useContext(AppContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [transactionsTableData, setTransactionsTableData] = useState([]);
  const [transactionsTablePagination, setTransactionsTablePagination] =
    useState({
      current: 1,
      pageSize: 15,
      total: 0,
    });
  const [reloadTableData, setReloadTableData] = useState(false);
  const [transaction, setTransaction] = useState({});
  const [searchParams, setSearchParams] = useState([]);
  const [slipModalVisible, setSlipModalVisible] = useState(false);
  const [refundModalVisible, setRefundModalVisible] = useState(false);
  const [refundType, setRefundType] = useState(REFUND_TYPE_FULL);
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalVAT, setTotalVAT] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [branchesData, setBranchesData] = useState([]);
  const [searchFormRef] = Form.useForm();
  const transactionSlipRef = useRef();
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    setIsDataLoading(true);
    getTransactions({
      ...searchParams,
      page: currentPage,
    })
      .then((response) => {
        setTransactionsTableData(response.data.data.transactions);
        setTransactionsTablePagination(response.data.data.pagination);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [searchParams, currentPage, reloadTableData]);

  useEffect(() => {
    let taxTotal = 0;
    let total = 0;
    let tax = 0;
    let subtotal = 0;
    let productPrice = 0;
    cart.forEach((product) => {
      transaction.discount
        ? (productPrice =
            product.price -
            product.price * (transaction.discount.discount_percentage / 100))
        : (productPrice = product.price);
      if (product.tax > 0) {
        tax = productPrice * TAX_PERCENTAGE;
        taxTotal += tax * product.quantity;
      }
      subtotal = productPrice * product.quantity;
      total = total + subtotal;
    });

    setTotalVAT(taxTotal);
    setTotalAmount(total + taxTotal);
  }, [cart, setTotalAmount, setTotalVAT, transaction.discount]);

  useEffect(() => {
    if (
      transaction &&
      refundModalVisible &&
      transaction.items &&
      transaction.items.length > 0
    ) {
      setCart(JSON.parse(JSON.stringify(transaction.items)));
    }
  }, [transaction, refundModalVisible]);

  useEffect(() => {
    setIsDataLoading(true);
    getBranches()
      .then((response) => {
        setBranchesData(response.data.data.branches);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const cartReducer = (product, action) => {
    let updatedCart = JSON.parse(JSON.stringify(cart));
    let productId = product.id;
    let indexFound = updatedCart.findIndex(
      (product) => product.id === productId
    );

    // if (action === "inc") {
    //   updatedCart[indexFound].quantity++;
    // } else
    if (action === "dec") {
      updatedCart[indexFound].quantity--;
      if (updatedCart[indexFound].quantity === 0) {
        updatedCart.splice(indexFound, 1);
      }
    }

    setCart(updatedCart);
  };

  const decrementProduct = (product) => {
    cartReducer(product, "dec");
  };

  const showSlipModal = (transaction) => {
    setTransaction(transaction);
    setSlipModalVisible(true);
  };

  const hideSlipModal = () => {
    setSlipModalVisible(false);
    setTransaction({});
  };

  const handleSendToOdoo = (id) => {
    sendTransactionToOdoo(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("send_to_odoo"),
            t("item_send_to_odoo_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
      });
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "transactions",
      breadcrumbName: t("menu_transactions"),
      icon: <DollarOutlined />,
    },
  ];

  const getTransactionsType = (type) => {
    if (type === TRANSACTION_TYPE_CASH) {
      return <Tag color="green">{t("cash")}</Tag>;
      // } else if (type === TRANSACTION_TYPE_MADA) {
      // return <Tag color="blue">MADA CARD</Tag>;
      // } else if (type === TRANSACTION_TYPE_STC) {
      // return <Tag color="purple">STC PAY</Tag>;
    } else if (type === TRANSACTION_TYPE_CREDIT) {
      return <Tag color="blue">{t("credit_card")}</Tag>;
    } else if (type === TRANSACTION_TYPE_MULTI) {
      return <Tag color="gold">{t("multi_payment")}</Tag>;
    }
  };

  // firstPartCol Columns
  const firstPartCol = [
    {
      title: t("col_transaction_id"),
      dataIndex: "uid",
      render: (uid) => {
        return <p>{uid}</p>;
      },
    },
    {
      title: t("col_amount"),
      render: (record) => {
        return (
          <>
            {record.is_multipayment ? (
              record.multipayments.map((item, i) => {
                return (
                  <p key={i}>
                    {item.transaction_type}: {item.amount}
                  </p>
                );
              })
            ) : (
              <p>
                <CurrencyFormatter value={record.amount_charged} />
              </p>
            )}
          </>
        );
      },
    },
    {
      title: t("col_tax"),
      dataIndex: "tax",
      render: (tax) => {
        return (
          <p>
            <CurrencyFormatter value={tax} />
          </p>
        );
      },
    },
  ];

  // Branch Columns
  const branchCol = [
    {
      title: t("branch"),
      dataIndex: "branch",
      render: (branch) => {
        return <p>{branch}</p>;
      },
    },
  ];
  // odoo Status Column
  const odooStatusColumn = [
    {
      title: t("col_odoo_status"),
      align: "center",
      render: (record) => {
        return (
          <>
            {record.odoo_reference_id ? (
              <CheckOutlined style={{ fontSize: "22px", color: "#52c41a" }} />
            ) : (
              <>
                <CloseOutlined
                  style={{
                    fontSize: "22px",
                    color: "#ff0000",
                    display: "block",
                    paddingBottom: "5px",
                  }}
                />
                <Button
                  type="primary"
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={() => handleSendToOdoo(record.id)}
                >
                  {t("send_to_odoo")}
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];
  // secondPartCol Columns
  const secondPartCol = [
    {
      title: t("col_type"),
      dataIndex: "type",
      render: (type) => {
        return <p>{getTransactionsType(type)}</p>;
      },
    },
    {
      title: t("col_status"),
      render: (transaction) => {
        return (
          <p>
            {transaction.reference_transaction ? (
              <>
                <Tag color="error">{t("status_refunded")}</Tag>
                <br />
                Ref: <strong>{transaction.reference_transaction.uid}</strong>
              </>
            ) : (
              <Tag color="success">{t("status_paid")}</Tag>
            )}
          </p>
        );
      },
    },
    {
      title: t("col_user"),
      dataIndex: "user",
    },
    {
      title: t("col_created_at"),
      dataIndex: "created_at",
      render: (created_at) => {
        return <p>{moment(created_at).format("MMM. D, YYYY hh:mm a")}</p>;
      },
    },
    {
      title: t("col_action"),
      render: (record) => {
        return (
          <>
            {!record.is_refunded &&
              !record.reference_transaction &&
              authUser.can_refund_transaction && (
                <Button
                  type="link"
                  onClick={() =>
                    // Using JSON Parse and Stringify for Deep Clone
                    showRefundModal(JSON.parse(JSON.stringify(record)))
                  }
                  style={{ padding: 2, fontSize: "20px", color: "#ff0000" }}
                  title="Refund Transaction"
                >
                  <UndoOutlined />
                </Button>
              )}
            <Button
              type="link"
              style={{ padding: 2 }}
              onClick={() => showSlipModal(record)}
            >
              <EyeTwoTone
                twoToneColor="#0000ff"
                title="View Details"
                style={{ fontSize: "20px" }}
              />
            </Button>
            <Button
              type="link"
              style={{ padding: 2 }}
              target="_blank"
              href={
                process.env.REACT_APP_API_ROOT +
                "/companies/" +
                record.company_id +
                "/transactions/" +
                record.id +
                "/generate-slip-pdf"
              }
            >
              <CloudDownloadOutlined
                style={{ fontSize: "20px", color: "#138d13" }}
                title="Download Slip in PDF"
              />
            </Button>
          </>
        );
      },
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;
    if (values.dates) {
      values.dates[0].set({
        hour: 0,
        minute: 0,
        second: 1,
        millisecond: 0,
      });
      values.dates[1].set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      });

      let startDateUtc = new moment(values.dates[0]).utc();
      let endDateUtc = new moment(values.dates[1]).utc();

      search_params = {
        search_start_date: startDateUtc.format(),
        search_end_date: endDateUtc.format(),
      };
    }
    if (values.type) {
      search_params = {
        type: values.type,
        ...search_params,
      };
    }
    if (values.is_refunded === 0 || values.is_refunded) {
      search_params = {
        is_refunded: values.is_refunded,
        ...search_params,
      };
    }
    if (values.filter_by_branch) {
      search_params = {
        filter_by_branch: values.filter_by_branch,
        ...search_params,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const showRefundModal = (transaction_row) => {
    setTransaction(transaction_row);
    setRefundModalVisible(true);
    setRefundType(REFUND_TYPE_FULL);
  };

  const hideRefundModal = () => {
    setCart([]);
    setRefundModalVisible(false);
    setTransaction({});
  };

  const searchForm = (
    <>
      <Form
        name="search-transaction-form"
        style={{ paddingBottom: "20px" }}
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="dates" label={t("date_range")}>
          <RangePicker allowClear format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="filter_by_branch" label={t("branch")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={branchesData.map((branch) => {
              return {
                label: branch.name,
                value: branch.name,
              };
            })}
          ></Select>
        </Form.Item>
        <Form.Item name="type" label={t("col_type")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={transactionTypes.map((type) => {
              return {
                label: type.text,
                value: type.id,
              };
            })}
          ></Select>
        </Form.Item>

        <Form.Item name="is_refunded" label={t("is_refunded")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={[
              {
                label: t("yes"),
                value: 1,
              },
              {
                label: t("no"),
                value: 0,
              },
            ]}
          ></Select>
        </Form.Item>

        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  const handleTransactionsTableChange = (pagination, filters, sorter) => {
    setTransactionsTablePagination(pagination);
    setCurrentPage(pagination.current);
  };

  const handleRefund = () => {
    refundTransaction({
      transaction_id: transaction.id,
      refund_type: refundType,
      items: JSON.stringify(
        cart.map((item) => ({
          id: item.product_id,
          qty: item.quantity,
        }))
      ),
    })
      .then((response) => {
        if (response.data.success) {
          NotificationWithIcon(
            "success",
            t("Transaction Refunded"),
            t("Transaction has been refunded successfully!")
          );
          setReloadTableData(!reloadTableData);
        }
      })
      .finally(() => {
        hideRefundModal();
      });
  };

  const handleDownloadReport = () => {
    downloadExcelReport().then((response) => {});
  };

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_transactions")}
        ghost={false}
        footer={searchForm}
        extra={[
          <Button type="primary" onClick={handleDownloadReport}>
            {t("download_report")}
          </Button>,
        ]}
      />
      <Card>
        <Table
          columns={
            authUser.type === USER_TYPE_BUSINESS_OWNER
              ? authUser.external_integrations.length
                ? [
                    ...firstPartCol,
                    ...branchCol,
                    ...odooStatusColumn,
                    ...secondPartCol,
                  ]
                : [...firstPartCol, ...branchCol, ...secondPartCol]
              : authUser.external_integrations.length
              ? [...firstPartCol, ...odooStatusColumn, ...secondPartCol]
              : [...firstPartCol, ...secondPartCol]
          }
          // columns={
          //   authUser.type === USER_TYPE_BUSINESS_OWNER
          //     ? [...firstPartCol, ...branchCol, ...secondPartCol]
          //     : [...firstPartCol, ...secondPartCol]
          // }
          dataSource={transactionsTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={{
            current: transactionsTablePagination.current_page,
            pageSize: transactionsTablePagination.per_page,
            total: transactionsTablePagination.total,
            showSizeChanger: false,
          }}
          onChange={handleTransactionsTableChange}
        />
      </Card>
      <Modal
        visible={slipModalVisible}
        title={t("col_transaction_id") + " # " + transaction.uid}
        onCancel={hideSlipModal}
        footer={[
          <ReactToPrint
            trigger={() => (
              <Button key="print" type="primary">
                {t("print")}
              </Button>
            )}
            content={() => transactionSlipRef.current}
          />,
          <Button key="back" onClick={hideSlipModal}>
            {t("back")}
          </Button>,
        ]}
      >
        <TransactionSlip
          ref={transactionSlipRef}
          transaction={transaction}
          shop={{
            name: authUser.business_name,
            vat: authUser.vat,
            logo: authUser.logo,
            is_vat_exempt: authUser.is_vat_exempt,
          }}
        />
      </Modal>
      <Modal
        visible={refundModalVisible}
        title={
          t("refund") + " " + t("col_transaction_id") + " # " + transaction.uid
        }
        onCancel={hideRefundModal}
        footer={[
          <Button
            type="primary"
            disabled={
              !(
                refundType === REFUND_TYPE_FULL ||
                (refundType === REFUND_TYPE_PARTIAL && totalAmount > 0)
              )
            }
            onClick={handleRefund}
          >
            {t("refund")}
          </Button>,
          <Button onClick={() => hideRefundModal()}>{t("cancel")}</Button>,
        ]}
      >
        <p>{t("what_type_of_refund")}</p>
        <Radio.Group
          value={refundType}
          onChange={(e) => {
            setRefundType(e.target.value);
            if (e.target.value === REFUND_TYPE_PARTIAL) {
              setCart(JSON.parse(JSON.stringify(transaction.items)));
            } else {
              setCart([]);
            }
          }}
          buttonStyle="solid"
        >
          <Radio.Button value={REFUND_TYPE_FULL}>{t("full")}</Radio.Button>
          <Radio.Button value={REFUND_TYPE_PARTIAL}>
            {t("partial")}
          </Radio.Button>
        </Radio.Group>
        <br />
        <br />
        {refundType === REFUND_TYPE_FULL ? (
          <>
            <h3 style={{ marginBottom: 0, paddingBottom: 0 }}>
              {t("refund_amount")}:
              <CurrencyFormatter value={transaction.amount_charged} />
            </h3>
            <h4>
              {t("including_vat")}:
              <CurrencyFormatter value={transaction.tax} />
            </h4>
          </>
        ) : (
          <>
            {cart.map((product) => {
              let subtotal = product.price * product.quantity;
              if (product.tax > 0) {
                subtotal += subtotal * TAX_PERCENTAGE;
              }

              return (
                <div
                  className="cart-products-list-item"
                  key={"cart-item-" + product.id}
                >
                  <strong className="product-name">
                    <span>
                      {appConfig.direction === "rtl"
                        ? product.name
                        : product.name_en}
                    </span>
                  </strong>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      {t("qty")}:
                      <MinusCircleFilled
                        style={{
                          color: "#6301f2",
                          fontSize: "16px",
                          marginLeft: "5px",
                        }}
                        onClick={() => decrementProduct(product)}
                      />
                      <strong style={{ margin: "0 10px" }}>
                        {product.quantity}
                      </strong>
                    </div>
                    <div>
                      {t("price")}:{" "}
                      <span className="brand-color-purple">
                        <CurrencyFormatter
                          value={product.price}
                          currencyCode={false}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="clear"></div>
                  {t("col_subtotal")}:{" "}
                  <span className="subtotal brand-color-purple">
                    <CurrencyFormatter value={subtotal} currencyCode={false} />
                  </span>
                </div>
              );
            })}
            {transaction.discount && (
              <h3 style={{ marginBottom: 0, paddingBottom: 0 }}>
                {t("discount")}: {transaction.discount.discount_percentage}%
              </h3>
            )}
            <h3 style={{ marginBottom: 0, paddingBottom: 0 }}>
              {t("refund_amount")}: <CurrencyFormatter value={totalAmount} />
            </h3>
            <h4>
              {t("including_vat")}: <CurrencyFormatter value={totalVAT} />
            </h4>
          </>
        )}
      </Modal>
    </>
  );
};

export default Transactions;
