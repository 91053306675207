import React, { useEffect, useState } from "react";
import { PageHeader, DatePicker, Form, Input, Card, Table, Button } from "antd";
import { HomeOutlined, BarChartOutlined } from "@ant-design/icons";
import { getReportByBranches } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { currencyFormatter } from "../../../utils";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CSVLink } from "react-csv";

const ReportByBranches = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchParams, setSearchParams] = useState([]);
  const [branchTransactionsData, setBranchTransactionsData] = useState([]);
  const [branchTransactionTypesData, setBranchTransactionTypesData] = useState(
    []
  );
  const { t } = useTranslation();
  const [searchFormRef] = Form.useForm();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    // Set Loading True
    setIsDataLoading(true);
    getReportByBranches(searchParams)
      .then((response) => {
        setBranchTransactionsData(response.data.data.transactions_by_branch);
        setBranchTransactionTypesData(
          response.data.data.type_transactions_by_branch
        );
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [searchParams]);

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: null,
      breadcrumbName: t("menu_reports") + " / " + t("menu_report_by_branch"),
      icon: <BarChartOutlined />,
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;
    if (values.dates) {
      values.dates[0].set({
        hour: 0,
        minute: 0,
        second: 1,
        millisecond: 0,
      });
      values.dates[1].set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      });

      let startDateUtc = new moment(values.dates[0]).utc();
      let endDateUtc = new moment(values.dates[1]).utc();

      search_params = {
        search_start_date: startDateUtc.format(),
        search_end_date: endDateUtc.format(),
      };
    }
    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };
  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-report-form"
        style={{ paddingBottom: "20px" }}
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="dates" label={t("date_range")}>
          <RangePicker allowClear format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  const salesColumns = [
    {
      title: t("menu_branches"),
      dataIndex: "name",
    },
    {
      title: t("gross_sales"),
      render: (data) => {
        return <>{currencyFormatter.format(data.total_charged_amount)}</>;
      },
    },
    {
      title: t("refunds"),
      render: (data) => {
        return <>{currencyFormatter.format(data.total_refunded_amount)}</>;
      },
    },
    {
      title: t("total_taxes"),
      render: (data) => {
        return <>{currencyFormatter.format(data.total_tax)}</>;
      },
    },
    {
      title: t("net_sales"),
      render: (data) => {
        return (
          <div style={{ fontWeight: "bold" }}>
            {currencyFormatter.format(
              data.total_charged_amount -
                Math.abs(data.total_refunded_amount) -
                data.total_tax
            )}
          </div>
        );
      },
    },
  ];

  const paymentsColumns = [
    {
      title: t("menu_branches"),
      dataIndex: "name",
    },
    {
      title: t("cash"),
      render: (data) => {
        return <>{currencyFormatter.format(data.total_charged_amount_1)}</>;
      },
    },
    // {
    //   title: t("mada"),
    //   render: (data) => {
    //     return <>{currencyFormatter.format(data.total_charged_amount_2)}</>;
    //   },
    // },
    // {
    //   title: t("stc_pay"),
    //   render: (data) => {
    //     return <>{currencyFormatter.format(data.total_charged_amount_3)}</>;
    //   },
    // },
    {
      title: t("credit_card"),
      render: (data) => {
        return <>{currencyFormatter.format(data.total_charged_amount_4)}</>;
      },
    },
    {
      title: t("multi_payment"),
      render: (data) => {
        return <>{currencyFormatter.format(data.total_charged_amount_5)} </>;
      },
    },
    {
      title: t("taxes"),
      render: (data) => {
        return (
          <>
            {currencyFormatter.format(
              Math.abs(data.total_tax_1) +
                // Math.abs(data.total_tax_2) +
                // Math.abs(data.total_tax_3) +
                Math.abs(data.total_tax_4) +
                Math.abs(data.total_tax_5)
            )}
          </>
        );
      },
    },
    {
      title: t("net_sales"),
      render: (data) => {
        return (
          <div style={{ fontWeight: "bold" }}>
            {currencyFormatter.format(
              Math.abs(data.total_charged_amount_1) +
                // Math.abs(data.total_charged_amount_2) +
                // Math.abs(data.total_charged_amount_3) +
                Math.abs(data.total_charged_amount_4) +
                Math.abs(data.total_charged_amount_5) -
                (Math.abs(data.total_tax_1) +
                  // Math.abs(data.total_tax_2) +
                  // Math.abs(data.total_tax_3) +
                  Math.abs(data.total_tax_4) +
                  Math.abs(data.total_tax_5))
            )}
          </div>
        );
      },
    },
  ];

  const salesCSVData = [
    ["Branches", "Gross Sales", "Refunds", "Total VAT on Sales", "Net Sales"],
    ...branchTransactionsData.map(
      ({ name, total_charged_amount, total_refunded_amount, total_tax }) => [
        name,
        currencyFormatter.format(total_charged_amount),
        currencyFormatter.format(total_refunded_amount),
        currencyFormatter.format(total_tax),
        currencyFormatter.format(
          total_charged_amount - Math.abs(total_refunded_amount) - total_tax
        ),
      ]
    ),
  ];

  const paymentsCSVData = [
    [
      "Branches",
      "Cash",
      "Credit Card",
      "Multi Payment",
      "VAT on Sales",
      "Net Sales",
    ],
    ...branchTransactionTypesData.map(
      ({
        name,
        total_charged_amount_1,
        total_charged_amount_4,
        total_charged_amount_5,
        total_tax_1,
        total_tax_4,
        total_tax_5,
      }) => [
        name,
        currencyFormatter.format(total_charged_amount_1),
        currencyFormatter.format(total_charged_amount_4),
        currencyFormatter.format(total_charged_amount_5),
        currencyFormatter.format(
          Math.abs(total_tax_1) + Math.abs(total_tax_4) + Math.abs(total_tax_5)
        ),
        currencyFormatter.format(
          Math.abs(total_charged_amount_1) +
            Math.abs(total_charged_amount_4) +
            Math.abs(total_charged_amount_5) -
            (Math.abs(total_tax_1) +
              Math.abs(total_tax_4) +
              Math.abs(total_tax_5))
        ),
      ]
    ),
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_report_by_branch")}
        breadcrumb={CustomBreadcrumb}
        ghost={false}
        footer={searchForm}
        extra={[
          <Button type="primary">
            <CSVLink
              data={salesCSVData}
              filename={
                "Sales by branch report" +
                " " +
                moment().format("MM-DD-YYYY HH-mm") +
                ".csv"
              }
              target="_blank"
            >
              {t("download_sales_report")}
            </CSVLink>
          </Button>,
          <Button type="primary">
            <CSVLink
              data={paymentsCSVData}
              filename={
                "Payments by branch report" +
                " " +
                moment().format("MM-DD-YYYY HH-mm") +
                ".csv"
              }
              target="_blank"
            >
              {t("download_payments_report")}
            </CSVLink>
          </Button>,
        ]}
      />
      <Card>
        <h3>{t("sales")}</h3>
        <Table
          columns={salesColumns}
          dataSource={branchTransactionsData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <br />
      <Card>
        <h3>{t("payments")}</h3>
        <Table
          columns={paymentsColumns}
          dataSource={branchTransactionTypesData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
    </>
  );
};

export default ReportByBranches;
