import "./Pricing.css";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Card, Button, Descriptions } from "antd";
import { currencyFormatter } from "../../utils";
import {
  PLAN_TYPE_BASIC,
  PLAN_TYPE_PRO,
  PERIOD_MONTHLY,
  PERIOD_YEARLY,
} from "../../configs/constants";
import { getSubscriptionPlansAsObject } from "../../network/network";
import { AppContext } from "../../context/AppContextProvider";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const Pricing = ({ callbackSelection }) => {
  const { displayWebView } = useContext(AppContext);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(PERIOD_YEARLY);
  const [selectedPlanType, setSelectedPlanType] = useState(false);
  const [usersCount, setUsersCount] = useState(1);
  const [userAmount, setUserAmount] = useState(0);
  const [planAmount, setPlanAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { t } = useTranslation();

  const tabList = [
    {
      key: PERIOD_MONTHLY,
      tab: `${t("monthly")}`,
    },
    {
      key: PERIOD_YEARLY,
      tab: `${t("yearly")}`,
    },
  ];

  useEffect(() => {
    getSubscriptionPlansAsObject().then((res) => {
      if (res.data && res.data.success) {
        setSubscriptionPlans(res.data.data.subscription_plans);
      }
    });
  }, []);

  useEffect(() => {
    calculateAmount();
  });
  useEffect(() => {
    setSelectedPlanType(PLAN_TYPE_PRO);
  }, []);

  const findPlan = (type, period) => {
    if (!subscriptionPlans.length) {
      return false;
    } else {
      return subscriptionPlans.find(
        (p) => p.type === type && p.period === period
      );
    }
  };

  const calculateAmount = () => {
    let plan;
    let _discount = 0;
    let _userAmount = 0;
    let _planAmount = 0;

    if (selectedPlanType === PLAN_TYPE_BASIC) {
      setUsersCount(1);
      if (selectedPeriod === PERIOD_MONTHLY) {
        plan = findPlan(PLAN_TYPE_BASIC, PERIOD_MONTHLY);
      } else {
        plan = findPlan(PLAN_TYPE_BASIC, PERIOD_YEARLY);
      }
    } else {
      if (selectedPeriod === PERIOD_MONTHLY) {
        plan = findPlan(PLAN_TYPE_PRO, PERIOD_MONTHLY);
      } else {
        plan = findPlan(PLAN_TYPE_PRO, PERIOD_YEARLY);
      }
      _discount += plan.user_discount * usersCount;
      _userAmount += plan.user_price * usersCount;
    }

    _discount += plan.discount;
    _planAmount += plan.price;

    setDiscount(_discount);
    setPlanAmount(_planAmount);
    setUserAmount(_userAmount);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <div
            className={
              displayWebView
                ? "pricing_head-webview pricing_head"
                : "pricing_head"
            }
          >
            <div className="user_selection">
              <h2 style={{ margin: 0 }}> {t("number_of_users")}</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "20px",
                  marginTop: "20px",
                }}
                className="users_count"
              >
                <MinusOutlined
                  style={{
                    fontSize: "18px",
                    padding: "10px",
                    backgroundColor: "#6301f2",
                    color: "#ffffff",
                  }}
                  onClick={() => {
                    usersCount > 1 && setUsersCount(usersCount - 1);
                  }}
                />
                <div style={{ fontSize: "18px" }}>{usersCount}</div>
                <PlusOutlined
                  style={{
                    fontSize: "18px",
                    padding: "10px",
                    backgroundColor: "#6301f2",
                    color: "#ffffff",
                  }}
                  onClick={() => setUsersCount(usersCount + 1)}
                />
              </div>
            </div>
            <Card
              tabList={tabList}
              activeTabKey={selectedPeriod.toString()}
              onTabChange={(key) => setSelectedPeriod(parseInt(key))}
              bodyStyle={{ padding: "0" }}
              className="package_tabs"
            ></Card>
          </div>
        </Col>
      </Row>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 40 }}
        style={{ marginTop: "20px" }}
      >
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
          {/* <h3>Package includes:</h3>
          {selectedPeriod === PERIOD_MONTHLY ? (
            <ul>
              <li>Monthly Feature 1</li>
              <li>Monthly Feature 2</li>
              <li>Monthly Feature 3</li>
              <li>Monthly Feature 4</li>
              <li>Monthly Feature 5</li>
            </ul>
          ) : (
            <ul>
              <li>Yearly Feature 1</li>
              <li>Yearly Feature 2</li>
              <li>Yearly Feature 3</li>
              <li>Yearly Feature 4</li>
              <li>Yearly Feature 5</li>
              <li>Yearly Feature 6</li>
              <li>Yearly Feature 7</li>
            </ul>
          )} */}

          <div className="pricing_area">
            <h2>{t("pricing")}</h2>
            {findPlan(PLAN_TYPE_PRO, selectedPeriod) && (
              <>
                <div style={{ fontWeight: "bold", fontSize: "250%" }}>
                  {currencyFormatter.format(
                    selectedPeriod === PERIOD_MONTHLY
                      ? findPlan(PLAN_TYPE_PRO, selectedPeriod).user_price
                      : (findPlan(PLAN_TYPE_PRO, selectedPeriod).user_price -
                          findPlan(PLAN_TYPE_PRO, selectedPeriod)
                            .user_discount) /
                          12
                  )}
                </div>
                {t("month_per_user")}
              </>
            )}
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={8}>
          <div className="free_trial">
            <img
              src="images/free-trial.png"
              alt="free trial"
              style={{ width: "100%", maxWidth: "180px", marginTop: "-65px" }}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={8}>
          {selectedPlanType && (
            <Descriptions
              bordered
              size="middle"
              column={{ xs: 8, sm: 16, md: 24 }}
              layout="horizontal"
            >
              {userAmount && (
                <Descriptions.Item
                  span={24}
                  label={
                    <>
                      {usersCount} {t("users")}
                    </>
                  }
                  contentStyle={{
                    textAlign: "right",
                    fontWeight: "bold",
                    padding: "8px 12px",
                  }}
                  labelStyle={{ padding: "8px 12px" }}
                >
                  {currencyFormatter.format(
                    selectedPeriod === PERIOD_MONTHLY
                      ? userAmount
                      : (userAmount - discount) / 12
                  )}
                </Descriptions.Item>
              )}

              <Descriptions.Item
                span={24}
                label={t("total_amount")}
                contentStyle={{
                  textAlign: "right",
                  fontWeight: "bold",
                  padding: "8px 12px",
                }}
                labelStyle={{ padding: "8px 12px" }}
              >
                {currencyFormatter.format(userAmount + planAmount - discount)}
              </Descriptions.Item>
            </Descriptions>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "25px",
            }}
          >
            <Button
              type="primary"
              disabled={!selectedPlanType}
              size="large"
              onClick={() =>
                callbackSelection(selectedPlanType, selectedPeriod, usersCount)
              }
              className={displayWebView ? "button-webview" : ""}
            >
              {t("sign_up_free_trial")}
            </Button>
          </div>
          {selectedPlanType && (
            <small
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginTop: "15px",
              }}
            >
              * {t("pay")}{" "}
              {currencyFormatter.format(userAmount + planAmount - discount)} +
              {t("VAT_once_trial_period_is_over")}
            </small>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Pricing;
