import { USER_TYPE_EMPLOYEE } from "./configs/constants";
import Forgot from "./pages/Auth/Forgot/Forgot";
import Login from "./pages/Auth/Login/Login";
import Register from "./pages/Auth/Register/Register";
import TransactionsInvoice from "./pages/Content/Transactions/TransactionsInvoice";

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "SAR",
});

export const filterPagesByAddons = (ContentPages, authUser) => {
  if (!authUser) {
    return [
      {
        path: "/login",
        name: "login",
        text: "login",
        component: Login,
        icon: false,
        showInSider: false,
      },
      {
        path: "/register",
        name: "register",
        text: "register",
        component: Register,
        icon: false,
        showInSider: false,
      },
      {
        path: "/forgot",
        name: "forgot",
        text: "forgot",
        component: Forgot,
        icon: false,
        showInSider: false,
      },
      {
        path: "/transactions/invoice",
        name: "transactions-invoice",
        text: "transactions_invoice",
        component: TransactionsInvoice,
        icon: false,
        showInSider: false,
      },
      {
        path: "*",
        name: "register",
        text: "register",
        component: Register,
        icon: false,
        showInSider: false,
      },
    ];
  }
  return ContentPages.filter((page) => {
    // check the store type
    if (authUser && authUser.addons.includes("multi_branch")) {
      if (
        authUser.type !== USER_TYPE_EMPLOYEE &&
        [
          "register-confirmed",
          "home",
          "pos",
          "products",
          "product-categories",
          "product-units",
          "transactions",
          "branches",
          "employees",
          "discounts",
          "helpdesk",
          "reports",
          "sales-summary",
          "sales-by-items",
          "sales-by-categories",
          "refunds-by-items",
          "refunds-by-categories",
          "report-by-branch",
          "store-configuration",
          "subscriptions",
          "invoices",
          "devices",
          "payments",
          "external-integration",
        ].includes(page.name)
      ) {
        return true;
      } else if (
        authUser.type === USER_TYPE_EMPLOYEE &&
        [
          "home",
          "pos",
          "products",
          "transactions",
          "reports",
          "sales-summary",
          "sales-by-items",
          "sales-by-categories",
          "refunds-by-items",
          "refunds-by-categories",
        ].includes(page.name)
      ) {
        return true;
      }
    } else if (
      authUser &&
      !authUser.addons.includes("multi_branch") &&
      [
        "register-confirmed",
        "home",
        "pos",
        "products",
        "product-categories",
        "product-units",
        "transactions",
        "reports",
        "sales-summary",
        "sales-by-items",
        "sales-by-categories",
        "refunds-by-items",
        "refunds-by-categories",
        "store-configuration",
        "subscriptions",
        "invoices",
        "devices",
        "payments",
        "external-integration",
      ].includes(page.name)
    ) {
      return true;
    }
    return false;
  });
};
