import React, { useEffect, useState, useContext } from "react";
import {
  PageHeader,
  Card,
  Table,
  Tag,
  Button,
  Modal,
  Descriptions,
  InputNumber,
  Alert,
} from "antd";
import { EyeOutlined, HomeOutlined, WalletOutlined } from "@ant-design/icons";
import {
  getSubscriptions,
  createSubscription,
  getSubscriptionPlansAsObject,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { currencyFormatter } from "../../../utils";
import {
  PLAN_TYPE_PRO,
  PLAN_TYPE_BASIC,
  PERIOD_MONTHLY,
  PERIOD_YEARLY,
  COMPANY_STATUS_SUBSCRIPTION_ENDED,
  SUBSCRIPTION_CHARGES_YEARLY,
  SUBSCRIPTION_CHARGES_MONTHLY,
  COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED,
} from "../../../configs/constants";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { AppContext } from "../../../context/AppContextProvider";

const Subscriptions = () => {
  const { authUser } = useContext(AppContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [subscriptionsTableData, setSubscriptionsTableData] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(false);
  const [requestedSubscription, setRequestedSubscription] = useState(false);
  const [upgradePlan, setUpgradePlan] = useState(false);
  const [usersCount, setUsersCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);

    getSubscriptions()
      .then((response) => {
        setSubscriptionsTableData(response.data.data.subscriptions);
        setActiveSubscription(response.data.data.active_subscription);
        if (response.data.data.active_subscription) {
          setUsersCount(
            response.data.data.active_subscription.user_licenses_count
          );
        }
        setRequestedSubscription(response.data.data.requested_subscription);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
    getSubscriptionPlansAsObject().then((response) => {
      setSubscriptionPlans(response.data.data.subscription_plans);
    });
  }, [reloadData]);

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "subscriptions",
      breadcrumbName: t("menu_subscriptions"),
      icon: <WalletOutlined />,
    },
  ];

  const subscriptionsTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("col_subscription_plan"),
      render: (subscription) => {
        return (
          <>
            {subscription.type === PLAN_TYPE_PRO ? (
              <Tag color="green">{subscription.name}</Tag>
            ) : (
              <Tag color="blue">{subscription.name}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("col_price"),
      render: (subscription) => {
        return (
          <>
            {currencyFormatter.format(subscription.amount)} /{" "}
            {subscription.period === PERIOD_YEARLY ? t("year") : t("month")}
            {subscription.type !== PLAN_TYPE_BASIC &&
              subscription.is_trial === 0 && (
                <>
                  <br />
                  <small>
                    + {t("additional")} {subscription.user_licenses_count} {t("users")} -{" "}
                    {currencyFormatter.format(
                      subscription.period === PERIOD_YEARLY
                        ? SUBSCRIPTION_CHARGES_YEARLY
                        : SUBSCRIPTION_CHARGES_MONTHLY
                    )}{" "}
                    / {subscription.period === PERIOD_YEARLY ? t("year") : t("month")}
                  </small>
                </>
              )}
            {subscription.is_trial === true && subscription.start_date && (
              <>
                <br />
                <small>
                  * {t("pay_on")}{" "}
                  {moment(subscription.start_date).format("MMM. D, YYYY")}{" "}
                </small>
              </>
            )}
          </>
        );
      },
    },
    // {
    //   title: t("no_of_licenses"),
    //   align: "center",
    //   render: (subscription) => {
    //     return (
    //       subscription.used_users_count +
    //       " / " +
    //       subscription.user_licenses_count
    //     );
    //   },
    // },
    {
      title: t("col_start_date"),
      dataIndex: "start_date",
      render: (start_date) => {
        return start_date ? moment(start_date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("col_end_date"),
      dataIndex: "end_date",
      render: (end_date) => {
        return end_date ? moment(end_date).format("MMM. D, YYYY") : "-";
      },
    },
    {
      title: t("action"),
      render: (subscription) => {
        return (
          <>
            {subscription.is_trial === 1 && <Tag>Trial</Tag>}
            {subscription.invoice && (
              <>
                <Button
                  type="default"
                  size="small"
                  target="_blank"
                  href={
                    process.env.REACT_APP_API_ROOT +
                    "/companies/" +
                    subscription.invoice.company_id +
                    "/invoices/" +
                    subscription.invoice.id
                  }
                >
                  <EyeOutlined />
                  View Invoice
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  const subscriptionPlanCard = (subscriptionPlan) => (
    <div
      style={{
        width: "300px",
        margin: "10px 10px 0 10px",
        padding: "20px",
        border: "1px solid #f0f0f0",
      }}
    >
      <h3>{subscriptionPlan.name}</h3>
      <p>
        {currencyFormatter.format(
          subscriptionPlan.price - subscriptionPlan.discount
        )}{" "}
        / {subscriptionPlan.period === PERIOD_YEARLY ? "Year" : "Month"}
        {subscriptionPlan.type !== PLAN_TYPE_BASIC ? (
          <>
            <br />
            <small>
              + Additional{" "}
              {currencyFormatter.format(
                subscriptionPlan.user_price - subscriptionPlan.user_discount
              )}{" "}
              /{" "}
              {subscriptionPlan.period === PERIOD_YEARLY ? "Yearly" : "Monthly"}{" "}
              for 1 User
            </small>
          </>
        ) : (
          <>
            <br />
            <br />
          </>
        )}
      </p>
      <div>
        {subscriptionPlan.type === PLAN_TYPE_BASIC ? (
          <Tag color="green">BASIC</Tag>
        ) : (
          <Tag color="purple">PRO</Tag>
        )}

        {subscriptionPlan.period === PERIOD_YEARLY ? (
          <Tag color="orange">YEARLY</Tag>
        ) : (
          <Tag color="blue">MONTHLY</Tag>
        )}
      </div>
    </div>
  );

  const handleSendRequest = () => {
    setLoading(true);
    createSubscription({
      type: upgradePlan.type,
      period: upgradePlan.period,
      users_count: usersCount,
    }).then((response) => {
      if (response.data.success === true) {
        // Success
        NotificationWithIcon(
          "success",
          t("subscription_upgrade_requested"),
          t("subscription_upgrade_requested_successfully")
        );

        setReloadData(!reloadData);
      }
      setUpgradePlan(false);
    });
  };

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_subscriptions")}
        ghost={false}
      />
      {authUser.company_status === COMPANY_STATUS_SUBSCRIPTION_ENDED && (
        <>
          <Alert
            message="Your account is freezed!"
            description="Kindly select a subscription upgrade to unfreeze it."
            type="info"
            showIcon
          />
          <br />
        </>
      )}
      <div style={{ display: "flex" }}>
        {activeSubscription ? (
          <Card
            title={t("active_subscription")}
            headStyle={{
              textAlign: "center",
              color: "#ffffff",
              backgroundColor: "#6301f2",
            }}
            style={{ marginRight: "20px" }}
          >
            <h3>{activeSubscription.name}</h3>
            <p>
              {moment(activeSubscription.start_date).format("MMM. D, YYYY")} -{" "}
              {moment(activeSubscription.end_date).format("MMM. D, YYYY")}
            </p>
            <p>
              {currencyFormatter.format(activeSubscription.amount)} /{" "}
              {activeSubscription.period === PERIOD_YEARLY ? "Year" : "Month"}
              {/* {activeSubscription.type !== PLAN_TYPE_BASIC && (
                <>
                  <br />
                  <small>
                    + Additional Users -{" "}
                    {currencyFormatter.format(
                      activeSubscription.period === PERIOD_YEARLY
                        ? SUBSCRIPTION_CHARGES_YEARLY
                        : SUBSCRIPTION_CHARGES_MONTHLY
                    )}{" "}
                    /{" "}
                    {activeSubscription.period === PERIOD_YEARLY
                      ? "Year"
                      : "Month"}
                  </small>
                </>
              )} */}
              {activeSubscription.is_trial === true &&
                activeSubscription.start_date && (
                  <>
                    <br />
                    <small>
                      * Pay on{" "}
                      {moment(activeSubscription.start_date).format(
                        "MMM. D, YYYY"
                      )}{" "}
                    </small>
                  </>
                )}
            </p>
            <div>
              {activeSubscription.type === PLAN_TYPE_BASIC ? (
                <Tag color="green">BASIC</Tag>
              ) : (
                <Tag color="purple">PRO</Tag>
              )}

              {activeSubscription.period === PERIOD_YEARLY ? (
                <Tag color="orange">YEARLY</Tag>
              ) : (
                <Tag color="blue">MONTHLY</Tag>
              )}
            </div>
          </Card>
        ) : (
          <Card
            title={t('available_upgrades')}
            headStyle={{
              textAlign: "center",
              backgroundColor: "#fafafa",
            }}
            bodyStyle={{ padding: "5px" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {authUser.company_status ===
              COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED ? (
                <Tag color="blue">Pay Generated Invoice First</Tag>
              ) : (
                subscriptionPlans.map((plan) => {
                  if (
                    !activeSubscription ||
                    (activeSubscription.type === PLAN_TYPE_BASIC &&
                      activeSubscription.period === PERIOD_MONTHLY &&
                      plan.period !== PERIOD_MONTHLY) ||
                    (activeSubscription.type === PLAN_TYPE_BASIC &&
                      activeSubscription.period === PERIOD_YEARLY &&
                      plan.type === PLAN_TYPE_PRO &&
                      plan.period === PERIOD_YEARLY) ||
                    (activeSubscription.type === PLAN_TYPE_PRO &&
                      activeSubscription.period === PERIOD_MONTHLY &&
                      plan.type === PLAN_TYPE_PRO &&
                      plan.period === PERIOD_YEARLY)
                  ) {
                    return (
                      <div key={plan.id}>
                        {subscriptionPlanCard(plan)}
                        <Button
                          disabled={requestedSubscription}
                          type="primary"
                          style={{
                            width: "300px",
                            margin: "0 10px",
                            // width: "100%",
                          }}
                          onClick={() => {
                            setLoading(false);
                            if (
                              activeSubscription &&
                              activeSubscription.user_licenses_count
                            ) {
                              setUsersCount(
                                activeSubscription.user_licenses_count
                              );
                            } else {
                              setUsersCount(1);
                            }
                            setUpgradePlan(plan);
                          }}
                        >
                          {requestedSubscription &&
                          requestedSubscription.name === plan.name
                            ? t("already_requested")
                            : t("request_upgrade")}
                        </Button>
                      </div>
                    );
                  } else {
                    return false;
                  }
                })
              )}
            </div>
          </Card>
        )}
      </div>
      <Card style={{ marginTop: "40px" }}>
        <Table
          columns={subscriptionsTableColumns}
          dataSource={subscriptionsTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        title={t("request_subscription_upgrade")}
        onOk={handleSendRequest}
        onCancel={() => setUpgradePlan(false)}
        okText="Send Request"
        visible={upgradePlan}
        confirmLoading={loading}
      >
        <p>You selected following package:</p>
        {subscriptionPlanCard(upgradePlan)}
        <h4>Calculations:</h4>

        <Descriptions
          bordered
          size="middle"
          column={{ xs: 8, sm: 16, md: 24 }}
          layout="horizontal"
        >
          {upgradePlan.type === PLAN_TYPE_PRO && (
            <Descriptions.Item
              span={24}
              label={
                <>
                  <InputNumber
                    style={{ width: "60px" }}
                    defaultValue={
                      activeSubscription
                        ? activeSubscription.user_licenses_count
                        : 1
                    }
                    min={
                      activeSubscription
                        ? activeSubscription.user_licenses_count
                        : 1
                    }
                    onChange={(value) => setUsersCount(value)}
                  />{" "}
                  User(s)
                </>
              }
              contentStyle={{
                textAlign: "right",
                fontWeight: "bold",
                padding: "8px 12px",
              }}
              labelStyle={{ padding: "8px 12px" }}
            >
              {currencyFormatter.format(upgradePlan.user_price * usersCount)}
            </Descriptions.Item>
          )}

          <Descriptions.Item
            span={24}
            label={
              upgradePlan.type === PLAN_TYPE_PRO ? t("pro_plan") : t("basic_plan")
            }
            contentStyle={{
              textAlign: "right",
              fontWeight: "bold",
              padding: "8px 12px",
            }}
            labelStyle={{ padding: "8px 12px" }}
          >
            {currencyFormatter.format(upgradePlan.price)}
          </Descriptions.Item>

          {(upgradePlan.discount || upgradePlan.user_discount) && (
            <Descriptions.Item
              span={24}
              label={t("discount")}
              contentStyle={{
                textAlign: "right",
                fontWeight: "bold",
                padding: "8px 12px",
              }}
              labelStyle={{ padding: "8px 12px" }}
            >
              {currencyFormatter.format(
                upgradePlan.discount * -1 +
                  upgradePlan.user_discount * usersCount * -1
              )}
            </Descriptions.Item>
          )}

          <Descriptions.Item
            span={24}
            label={t("total_amount_payable")}
            contentStyle={{
              textAlign: "right",
              fontWeight: "bold",
              padding: "8px 12px",
            }}
            labelStyle={{ padding: "8px 12px" }}
          >
            {currencyFormatter.format(
              (upgradePlan.user_price - upgradePlan.user_discount) *
                usersCount +
                upgradePlan.price -
                upgradePlan.discount
            )}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  );
};

export default Subscriptions;
