import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "../pages/Content/Home/Home";
import POS from "../pages/Content/POS/POS";
import Products from "../pages/Content/Products/Products";
import ProductCategories from "../pages/Content/ProductCategories/ProductCategories";
import ProductUnits from "../pages/Content/ProductUnits/ProductUnits";
import Transactions from "../pages/Content/Transactions/Transactions";
import StoreConfiguration from "../pages/Content/StoreConfiguration/StoreConfiguration";
import Branches from "../pages/Content/Branches/Branches";
import Employees from "../pages/Content/Employees/Employees";
import Helpdesk from "../pages/Content/Helpdesk/Helpdesk";
import SalesSummary from "../pages/Content/Reports/SalesSummary";
import SalesByItems from "../pages/Content/Reports/SalesByItems";
import SalesByCategories from "../pages/Content/Reports/SalesByCategories";
import RefundsByItems from "../pages/Content/Reports/RefundsByItems";
import RefundsByCategories from "../pages/Content/Reports/RefundsByCategories";
import ReportByBranches from "../pages/Content/Reports/ReportByBranches";
import Invoices from "../pages/Content/Invoices/Invoices";
import Devices from "../pages/Content/Devices/Devices";
import Payments from "../pages/Content/Payments/Payments";
import ExternalIntegration from "../pages/Content/ExternalIntegration/ExternalIntegration";
import {
  BarsOutlined,
  BarcodeOutlined,
  HomeOutlined,
  BgColorsOutlined,
  DollarOutlined,
  BarChartOutlined,
  SettingOutlined,
  ShopOutlined,
  WalletOutlined,
  EnvironmentOutlined,
  TeamOutlined,
  AccountBookOutlined,
  DesktopOutlined,
  CustomerServiceOutlined,
  PercentageOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import Subscriptions from "../pages/Content/Subscriptions/Subscriptions";
import { AppContext } from "../context/AppContextProvider";
import { filterPagesByAddons } from "../utils.js";
import Discounts from "../pages/Content/Discounts/Discounts";
import RegisterConfirmed from "../pages/Auth/Register/RegisterConfirmed";

export const ContentPages = [
  {
    path: "/",
    name: "home",
    text: "menu_home",
    component: Home,
    icon: HomeOutlined,
    showInSider: true,
  },
  {
    path: "/pos",
    name: "pos",
    text: "menu_pos",
    component: POS,
    icon: ShopOutlined,
    showInSider: true,
  },
  {
    path: "/products",
    name: "products",
    text: "menu_products",
    component: Products,
    icon: BarcodeOutlined,
    showInSider: true,
  },
  {
    path: "/product-categories",
    name: "product-categories",
    text: "menu_product_categories",
    component: ProductCategories,
    icon: BarsOutlined,
    showInSider: true,
  },
  {
    path: "/product-units",
    name: "product-units",
    text: "menu_product_units",
    component: ProductUnits,
    icon: BgColorsOutlined,
    showInSider: true,
  },
  {
    path: "/transactions",
    name: "transactions",
    text: "menu_transactions",
    component: Transactions,
    icon: DollarOutlined,
    showInSider: true,
  },
  {
    path: "/branches",
    name: "branches",
    text: "menu_branches",
    component: Branches,
    icon: EnvironmentOutlined,
    showInSider: true,
  },
  {
    path: "/employees",
    name: "employees",
    text: "menu_employees",
    component: Employees,
    icon: TeamOutlined,
    showInSider: true,
  },
  {
    path: "/discounts",
    name: "discounts",
    text: "menu_discounts",
    component: Discounts,
    icon: PercentageOutlined,
    showInSider: true,
  },
  {
    path: "/helpdesk",
    name: "helpdesk",
    text: "menu_help_desk",
    component: Helpdesk,
    icon: CustomerServiceOutlined,
    showInSider: true,
  },

  {
    name: "reports",
    text: "menu_reports",
    icon: BarChartOutlined,
    showInSider: true,
    subMenus: [
      {
        path: "/reports/sales-summary",
        name: "sales-summary",
        text: "menu_sales_summary",
        component: SalesSummary,
        icon: SettingOutlined,
        showInSider: true,
      },
      {
        path: "/reports/sales-by-items",
        name: "sales-by-items",
        text: "menu_sales_by_items",
        component: SalesByItems,
        icon: SettingOutlined,
        showInSider: true,
      },
      {
        path: "/reports/sales-by-categories",
        name: "sales-by-categories",
        text: "menu_sales_by_categories",
        component: SalesByCategories,
        icon: SettingOutlined,
        showInSider: true,
      },
      {
        path: "/reports/refunds-by-items",
        name: "refunds-by-items",
        text: "menu_refunds_by_items",
        component: RefundsByItems,
        icon: SettingOutlined,
        showInSider: true,
      },
      {
        path: "/reports/refunds-by-categories",
        name: "refunds-by-categories",
        text: "menu_refunds_by_categories",
        component: RefundsByCategories,
        icon: SettingOutlined,
        showInSider: true,
      },
      {
        path: "/reports/report-by-branch",
        name: "report-by-branch",
        text: "menu_report_by_branch",
        component: ReportByBranches,
        icon: SettingOutlined,
        showInSider: true,
      },
    ],
  },
  {
    path: "/store-configuration",
    name: "store-configuration",
    text: "menu_store_configuration",
    component: StoreConfiguration,
    icon: SettingOutlined,
    showInSider: true,
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    text: "menu_subscriptions",
    component: Subscriptions,
    icon: WalletOutlined,
    showInSider: true,
  },
  {
    path: "/invoices",
    name: "invoices",
    text: "menu_invoices",
    component: Invoices,
    icon: AccountBookOutlined,
    showInSider: true,
  },
  {
    path: "/devices",
    name: "devices",
    text: "menu_devices",
    component: Devices,
    icon: DesktopOutlined,
    showInSider: true,
  },
  {
    path: "/payments",
    name: "payments",
    text: "",
    component: Payments,
    icon: DesktopOutlined,
    showInSider: false,
  },
  //   {
  //     path: "/external-integration",
  //     name: "external-integration",
  //     text: "menu_external_integration",
  //     component: ExternalIntegration,
  //     icon: ApiOutlined,
  //     showInSider: true,
  //   },
  {
    path: "/register-confirmed",
    name: "register-confirmed",
    text: "register_confirmed",
    component: RegisterConfirmed,
    icon: false,
    showInSider: false,
  },
];

const Routes = () => {
  const { authUser } = useContext(AppContext);

  // State variable to track if the new menu item has been added
  const [isMenuItemAdded, setIsMenuItemAdded] = useState(false);

  useEffect(() => {
    // Add menu items dynamically
    if (authUser && authUser.features && authUser.features.some((feature) => {
        return feature.title === 'External Integration' && feature.status === 1;
      }) && !isMenuItemAdded) {
      ContentPages.push({
        path: "/external-integration",
        name: "external-integration",
        text: "menu_external_integration",
        component: ExternalIntegration,
        icon: ApiOutlined,
        showInSider: true,
      });

      // Set isMenuItemAdded to true to prevent adding the item again
      setIsMenuItemAdded(true);
    }
  }, [authUser, isMenuItemAdded]);

  return (
    <>
      <Switch>
        <Route exact path="/home">
          <Redirect to="/" />
        </Route>

        {filterPagesByAddons(ContentPages, authUser).map(function (page, i) {
          if (page.component) {
            return (
              <Route
                exact
                path={page.path}
                name={page.name}
                component={page.component}
                key={page.name}
              />
            );
          } else {
            let subRoutes =
              page.subMenus &&
              filterPagesByAddons(page.subMenus, authUser).map(function (
                subPage,
                i
              ) {
                if (subPage.component) {
                  return (
                    <Route
                      exact
                      path={subPage.path}
                      name={subPage.name}
                      component={subPage.component}
                      key={subPage.name}
                    />
                  );
                }
                return false;
              });
            return subRoutes;
          }
        })}
      </Switch>
    </>
  );
};

export default Routes;
