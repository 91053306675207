import React, { useContext, useEffect, useState } from "react";
import {
  PageHeader,
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
} from "antd";
import {
  HomeOutlined,
  DeleteTwoTone,
  EnvironmentOutlined,
  EditTwoTone,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  getBranches,
  createBranch,
  updateBranch,
  deleteBranch,
  sendBranchToOdoo,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContextProvider";

const Branches = () => {
  const { authUser } = useContext(AppContext);
  const ADD_BRANCH = "Add Branch";
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [BranchesTableData, setBranchesTableData] = useState([]);
  const [modalTitle, setModalTitle] = useState(ADD_BRANCH);
  const [addBranchForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getBranches()
      .then((response) => {
        setBranchesTableData(response.data.data.branches);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  const showModal = () => {
    // Show Modal
    setModalVisible(true);
    // Set Modal Title
    setModalTitle(t("branch_add"));
    // Reset Form Fields
    addBranchForm.resetFields();
  };

  const handleAPIResponse = (response, type) => {
    if (response.data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_BRANCH) {
        NotificationWithIcon(
          "success",
          t("branch_added"),
          t("branch_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("branch_updated"),
          t("branch_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        response.data.message
      );
    }
    // Reset Form Fields
    addBranchForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    addBranchForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        if (modalTitle === ADD_BRANCH || modalTitle === t("branch_add")) {
          createBranch(values)
            .then((response) => handleAPIResponse(response, ADD_BRANCH))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateBranch(values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (id) => {
    // Find branch from DataArray
    const branch = BranchesTableData.find((branch) => branch.id === id);

    // Set Modal Title
    setModalTitle(t("branch_edit") + " : " + branch.name);

    // Show Modal
    setModalVisible(true);
    // Set Form Fields
    addBranchForm.setFieldsValue({
      id: branch.id,
      name: branch.name,
      address: branch.address,
      code: branch.code,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    addBranchForm.resetFields();
    setModalVisible(false);
  };

  const handleDeleteBranch = (id) => {
    deleteBranch(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("branch_deleted"),
            t("branch_deleted_successfully")
          );
        } else {
        }
      })
      .catch((info) => {
        // Error
        console.log("Delete error: ", info.response.data.message);
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const handleSendToOdoo = (id) => {
    sendBranchToOdoo(id)
      .then((response) => {
        let data = response.data || response.response.data;
        if (data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("send_to_odoo"),
            t("item_send_to_odoo_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
      });
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "branches",
      breadcrumbName: t("menu_branches"),
      icon: <EnvironmentOutlined />,
    },
  ];

  const detailsPartColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      width: "20%",
    },
    {
      title: t("address"),
      dataIndex: "address",
      width: "30%",
    },
    {
      title: t("company"),
      dataIndex: "company",
      width: "20%",
    },
    {
      title: t("code"),
      dataIndex: "code",
    },
  ];

  const odooStatusColumn = [
    {
      title: t("col_odoo_status"),
      align: "center",
      render: (record) => {
        return (
          <>
            {record.odoo_reference_id ? (
              <CheckOutlined style={{ fontSize: "22px", color: "#52c41a" }} />
            ) : (
              <>
                <CloseOutlined
                  style={{
                    fontSize: "22px",
                    color: "#ff0000",
                    display: "block",
                    paddingBottom: "5px",
                  }}
                />
                <Button
                  type="primary"
                  size="small"
                  style={{ marginLeft: "10px" }}
                  onClick={() => handleSendToOdoo(record.id)}
                >
                  {t("send_to_odoo")}
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];
  const actionPartColumn = [
    {
      title: t("col_action"),
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("branch_edit")}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handleDeleteBranch(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_branches")}
        ghost={false}
        extra={[
          <Button key="open-add-store-modal" type="primary" onClick={showModal}>
            + {t("branch_add")}
          </Button>,
        ]}
      />
      <Card>
        <Table
          columns={
            authUser.external_integrations.length
              ? [
                  ...detailsPartColumns,
                  ...odooStatusColumn,
                  ...actionPartColumn,
                ]
              : [...detailsPartColumns, ...actionPartColumn]
          }
          dataSource={BranchesTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_BRANCH || modalTitle === t("branch_add")
              ? t("branch_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-store-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={addBranchForm}
        >
          <Form.Item
            name="name"
            label={t("name")}
            rules={[
              {
                required: true,
                message: t("validate_name_required"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label={t("address")}
            rules={[
              {
                required: true,
                message: t("validate_address"),
                whitespace: true,
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item name="code" label={t("code")} hasFeedback>
            <Input />
          </Form.Item>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Branches;
