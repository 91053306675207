import React from "react";
import ReactDOM from "react-dom";
import App from "./pages/App/App";
import AppContextProvider from "./context/AppContextProvider";
import { BrowserRouter as Router } from "react-router-dom";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
