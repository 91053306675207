import React, { useEffect, useState } from "react";
import {
  PageHeader,
  DatePicker,
  Form,
  Input,
  Card,
  Table,
  Select,
  Button,
} from "antd";
import { HomeOutlined, BarChartOutlined } from "@ant-design/icons";
import { getBranches, getSalesByItems } from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { currencyFormatter } from "../../../utils";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CSVLink } from "react-csv";

const SalesByItems = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reportTableData, setReportTableData] = useState([]);
  const [searchParams, setSearchParams] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [totalAmont, setTotalAmont] = useState([]);
  const { t } = useTranslation();
  const [searchFormRef] = Form.useForm();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    setIsDataLoading(true);
    setReportTableData([]);
    getSalesByItems(searchParams).then((response) => {
      setReportTableData(response.data.data.sales_by_items);
      setIsDataLoading(false);
    });
  }, [searchParams]);

  useEffect(() => {
    setIsDataLoading(true);
    getBranches()
      .then((response) => {
        setBranchesData(response.data.data.branches);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  useEffect(() => {
    let total = 0;
    reportTableData.forEach(({ net_amount, total_tax }) => {
      total = total + net_amount - total_tax;
    });
    setTotalAmont(currencyFormatter.format(total));
  }, [reportTableData]);

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: null,
      breadcrumbName: t("menu_reports") + " / " + t("menu_sales_by_items"),
      icon: <BarChartOutlined />,
    },
  ];

  const reportTableColumns = [
    {
      title: t("col_product"),
      dataIndex: "name",
    },
    {
      title: t("col_barcode"),
      dataIndex: "barcode",
    },
    {
      title: t("col_category"),
      dataIndex: "category",
    },
    {
      title: t("col_unit"),
      dataIndex: "unit",
    },
    {
      title: t("branch"),
      dataIndex: "branch_name",
    },
    {
      title: t("col_sold_quantities"),
      dataIndex: "quantity_sold",
    },
    {
      title: t("col_gross_sales"),
      render: (record) => {
        return currencyFormatter.format(record.net_amount - record.total_tax);
      },
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;
    if (values.dates) {
      values.dates[0].set({
        hour: 0,
        minute: 0,
        second: 1,
        millisecond: 1,
      });
      values.dates[1].set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 59,
      });

      let startDateUtc = new moment(values.dates[0]).utc();
      let endDateUtc = new moment(values.dates[1]).utc();

      search_params = {
        search_start_date: startDateUtc.format(),
        search_end_date: endDateUtc.format(),
      };
    }

    if (values.filter_by_branch) {
      search_params = {
        filter_by_branch: values.filter_by_branch,
        ...search_params,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };
  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const csvData = [
    [
      "Product",
      "Barcode",
      "Category",
      "Unit",
      "Branch",
      "Sold Quantities",
      "Gross Sales",
    ],
    ...reportTableData.map(
      ({
        name,
        barcode,
        category,
        unit,
        branch_name,
        quantity_sold,
        net_amount,
        total_tax,
      }) => [
        name,
        barcode,
        category,
        unit,
        branch_name,
        quantity_sold,
        currencyFormatter.format(net_amount - total_tax),
      ]
    ),
    [],
    ["", "", "", "", "", "Total Sales", totalAmont],
  ];

  const searchForm = (
    <>
      <Form
        name="search-report-form"
        style={{ paddingBottom: "20px" }}
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="dates" label={t("date_range")}>
          <RangePicker allowClear format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="filter_by_branch" label={t("branch")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={branchesData.map((branch) => {
              return {
                label: branch.name,
                value: branch.name,
              };
            })}
          ></Select>
        </Form.Item>
        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_sales_by_items")}
        breadcrumb={CustomBreadcrumb}
        ghost={false}
        footer={searchForm}
        extra={[
          <Button type="primary">
            <CSVLink
              data={csvData}
              filename={
                "Sales by items report" +
                " " +
                moment().format("MM-DD-YYYY HH-mm") +
                ".csv"
              }
              target="_blank"
            >
              {t("download_report")}
            </CSVLink>
          </Button>,
        ]}
      />
      <Card>
        <Table
          bordered={true}
          columns={reportTableColumns}
          dataSource={reportTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
          summary={(pageData) => {
            let totalSales = 0;

            pageData.forEach(({ net_amount, total_tax }) => {
              totalSales = totalSales + net_amount - total_tax;
            });

            return (
              <>
                <Table.Summary.Row
                  style={{ backgroundColor: "#fafafa", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell colSpan={6}>
                    {t("total_sales")}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {currencyFormatter.format(totalSales)}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </Card>
    </>
  );
};

export default SalesByItems;
