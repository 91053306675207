import React, { useEffect, useState } from "react";
import { PageHeader, Card, Table, Tag } from "antd";
import { DesktopOutlined, HomeOutlined } from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { getdevices } from "../../../network/network";
import { currencyFormatter } from "../../../utils";
import moment from "moment";

const Devices = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [devicesTableData, setDevicesTableData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    // Set Loading True
    setIsDataLoading(true);
    getdevices()
      .then((response) => {
        setDevicesTableData(response.data.data.devices);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "devices",
      breadcrumbName: t("menu_devices"),
      icon: <DesktopOutlined />,
    },
  ];

  const DevicesTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("model"),
      dataIndex: "model",
    },
    {
      title: t("imei"),
      dataIndex: "imei",
    },
    {
      title: t("serial_no"),
      dataIndex: "serial_no",
    },
    {
      title: t("amount"),
      dataIndex: "amount",
      render: (amount) => {
        return currencyFormatter.format(amount);
      },
    },
    {
      title: t("due_amount"),
      dataIndex: "due_amount",
      render: (due_amount) => {
        return currencyFormatter.format(due_amount);
      },
    },
    {
      title: t("installments"),
      dataIndex: "installments",
      render: (installments) => {
        return (
          <Tag color="blue">
            {installments === 1
              ? t("one_time_payment")
              : installments + t("months_plan")}
          </Tag>
        );
      },
    },
    {
      title: t("col_payment"),
      dataIndex: "due_amount",
      render: (due_amount) => {
        return (
          <>
            {due_amount === 0 ? (
              <Tag color="green">{t("complete")}</Tag>
            ) : (
              <Tag color="red">{t("incomplete")}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("warranty_from_to"),
      align: "center",
      render: (devices) => {
        return (
          <>
            {moment(devices.warranty_starting_at).format("MMM. D, YYYY")}
            <div>-</div>
            {moment(devices.warranty_ending_at).format("MMM. D, YYYY")}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <>
        <CustomBreadcrumb routes={breadcrumbRoutes} />
        <PageHeader
          className="site-page-header"
          title={t("menu_devices")}
          ghost={false}
        />
        <Card>
          <Table
            columns={DevicesTableColumns}
            dataSource={devicesTableData}
            loading={isDataLoading}
            rowKey="id"
            pagination={false}
          />
        </Card>
      </>
    </div>
  );
};

export default Devices;
