import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Select,
  Tag,
} from "antd";
import {
  HomeOutlined,
  DeleteTwoTone,
  EnvironmentOutlined,
  EditTwoTone,
} from "@ant-design/icons";
import {
  getBranches,
  getAllDiscounts,
  createDiscount,
  updateDiscount,
  deleteDiscount,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";

const Discounts = () => {
  const ADD_DISCOUNT = "Add Discount";
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [discountTableData, setDiscountTableData] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [modalTitle, setModalTitle] = useState(ADD_DISCOUNT);
  const [addDiscountForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getAllDiscounts()
      .then((response) => {
        setDiscountTableData(response.data.data.discounts);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  useEffect(() => {
    setIsDataLoading(true);
    getBranches()
      .then((response) => {
        setBranchesData(response.data.data.branches);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const showModal = () => {
    // Show Modal
    setModalVisible(true);
    // Set Modal Title
    setModalTitle(t("discount_add"));
    // Reset Form Fields
    addDiscountForm.resetFields();
  };

  const handleAPIResponse = (response, type) => {
    if (response.data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_DISCOUNT) {
        NotificationWithIcon(
          "success",
          t("discount_added"),
          t("discount_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("discount_updated"),
          t("discount_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        response.data.message
      );
    }
    // Reset Form Fields
    addDiscountForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    addDiscountForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        if (modalTitle === ADD_DISCOUNT || modalTitle === t("discount_add")) {
          createDiscount(values)
            .then((response) => handleAPIResponse(response, ADD_DISCOUNT))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateDiscount(values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (id) => {
    // Find discount from DataArray
    const discount = discountTableData.find((discount) => discount.id === id);

    // Set Modal Title
    setModalTitle(t("discount_edit") + " : " + discount.name);

    // Show Modal
    setModalVisible(true);
    // Set Form Fields
    addDiscountForm.setFieldsValue({
      id: discount.id,
      discount_percentage: discount.discount_percentage,
      branches: discount.branches.map(({ id }) => id),
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    addDiscountForm.resetFields();
    setModalVisible(false);
  };

  const handleDelete = (id) => {
    deleteDiscount(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("discount_deleted"),
            t("discount_deleted_successfully")
          );
        } else {
        }
      })
      .catch((info) => {
        // Error
        console.log("Delete error: ", info.response.data.message);
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const breadcrumbRoutes = [
    {
      path: "home",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "discounts",
      breadcrumbName: t("menu_discounts"),
      icon: <EnvironmentOutlined />,
    },
  ];

  const DiscountTableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      align: "center",
      render: (text, record, index) => {
        return <p>{index + 1}</p>;
      },
    },
    {
      title: t("col_discount_percentage"),
      dataIndex: "discount_percentage",
      width: "20%",
      render: (discount_percentage) => {
        return <>{discount_percentage} %</>;
      },
    },
    {
      title: t("col_branches"),
      dataIndex: "branches",
      render: (branches) => {
        return branches.map((branch, i) => {
          return (
            <Tag color="blue" key={i}>
              {branch.name}
            </Tag>
          );
        });
      },
    },

    {
      title: t("col_action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("discount_edit")}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handleDelete(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <CustomBreadcrumb routes={breadcrumbRoutes} />
      <PageHeader
        className="site-page-header"
        title={t("menu_discounts")}
        ghost={false}
        extra={[
          <Button
            key="open-add-discount-modal"
            type="primary"
            onClick={showModal}
          >
            + {t("discount_add")}
          </Button>,
        ]}
      />
      <Card>
        <Table
          columns={DiscountTableColumns}
          dataSource={discountTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_DISCOUNT || modalTitle === t("discount_add")
              ? t("discount_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-discount-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={addDiscountForm}
        >
          <Form.Item
            name="discount_percentage"
            label={t("col_discount_percentage")}
            rules={[
              {
                required: true,
                message: t("validate_discount_required"),
              },
            ]}
            hasFeedback
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="branches"
            label={t("col_branches")}
            rules={[
              {
                required: true,
                message: t("validate_branch_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              mode="multiple"
              allowClear
              options={branchesData.map((branch) => {
                return {
                  label: branch.name,
                  value: branch.id,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Discounts;
