import "./App.less";
import { useEffect, useContext } from "react";
import { Alert, ConfigProvider, Spin } from "antd";
import ar_EG from "antd/lib/locale/ar_EG";
import en_GB from "antd/lib/locale/en_GB";
import Login from "../Auth/Login/Login";
import Register from "../Auth/Register/Register";
import Dashboard from "../Dashboard/Dashboard";
import { AppContext } from "../../context/AppContextProvider";
import { Route, Switch, useHistory } from "react-router-dom";
import { getMyProfile } from "../../network/network";
import { useTranslation } from "react-i18next";
import { messaging as FCMMessaging } from "../../firebaseInit";
import { onMessage } from "firebase/messaging";
import NotificationWithIcon from "../../components/NotificationWithIcon";
import Forgot from "../Auth/Forgot/Forgot";
import TransactionsInvoice from "../Content/Transactions/TransactionsInvoice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const App = (props) => {
  const {
    authUser,
    setAuthUser,
    fullPageLoading,
    setFullPageLoading,
    setActiveMenu,
    appConfig,
    displayWebView,
    setDisplayWebView,
  } = useContext(AppContext);
  let history = useHistory();
  const { i18n } = useTranslation();
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const viewType = query.get("view");
    if (viewType === "webview") {
      setDisplayWebView(viewType);
    }
  }, [search, setDisplayWebView]);

  useEffect(() => {
    var access_token = localStorage.getItem("access_token");
    if (window.location.pathname === "/transactions/invoice") {
      history.push(window.location.pathname + window.location.search);
    } else if (access_token) {
      setFullPageLoading(true);
      getMyProfile()
        .then((response) => {
          setAuthUser(response.data.data.user);
          if (response.data.data.user) {
            if (response.data.data.user.is_onboarding_complete !== 1) {
              history.push("/register-confirmed");
            }
            // Redirect to last visited page
            else if (window.location.pathname !== "/") {
              if (localStorage.getItem("last_visited_page")) {
                const lastVisitedPage = JSON.parse(
                  localStorage.getItem("last_visited_page")
                );
                if (lastVisitedPage.key && lastVisitedPage.path) {
                  setActiveMenu(lastVisitedPage.key);
                } else {
                  history.push("/home");
                }
              } else {
                history.push("/home");
              }
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          setFullPageLoading(false);
        });
    } else {
      if (window.location.pathname === "/register") {
        if (displayWebView) {
          const params = new URLSearchParams();
          params.append("view", displayWebView);
          history.push({
            pathname: "/register",
            search: "?" + params.toString(),
          });
          // } else {
          //   history.push("/register");
        }
      } else {
        history.push("/login");
      }
    }
  }, [
    setAuthUser,
    setFullPageLoading,
    setActiveMenu,
    history,
    authUser.is_onboarding_complete,
    search,
    displayWebView,
  ]);

  useEffect(() => {
    if (appConfig.direction === "rtl") {
      i18n.changeLanguage("ar-EG");
    } else {
      i18n.changeLanguage("en-GB");
    }
  }, [appConfig, i18n]);

  // const retrieveDeviceToken = useCallback(async () => {
  //   let device_token = await getFCMToken();
  //   if (authUser && device_token && authUser.device_token !== device_token) {
  //     updateAuthDetails({
  //       device_token: device_token,
  //     }).then((response) => {
  //       console.log(response);
  //       if (response.data.success === true) {
  //         console.log("Device Token has been updated!");
  //       }
  //     });
  //   }
  // }, [authUser]);

  // useEffect(() => {
  //   retrieveDeviceToken();
  // }, [retrieveDeviceToken]);

  // Register FCM Listener
  onMessage(FCMMessaging, (payload) => {
    console.log(payload);
    if (
      payload &&
      payload.notification &&
      payload.notification.body === "RELOAD"
    ) {
      window.location.reload();
    } else if (
      payload &&
      payload.notification.body &&
      payload.notification.title &&
      payload.notification.body
    ) {
      NotificationWithIcon(
        "info",
        payload.notification.title,
        payload.notification.body
      );
    }
  });

  return (
    <ConfigProvider
      direction={appConfig.direction}
      locale={appConfig.direction === "ltr" ? en_GB : ar_EG}
    >
      <div className={appConfig.direction === "rtl" ? "app_rtl" : "app_ltr"}>
        {process.env.REACT_APP_MODE !== "LIVE" && (
          <Alert
            message="Dev Server"
            type="warning"
            banner
            style={{ fontWeight: "bold" }}
          />
        )}
        {fullPageLoading && (
          <div
            style={{
              textAlign: "center",
              paddingTop: "45vh",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              background: "#ffffff",
              zIndex: "999999",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {!fullPageLoading && !authUser ? (
          <Switch>
            <Route
              exact
              path="/login"
              name="Login"
              component={Login}
              key="login"
            />
            <Route
              exact
              path="/register"
              name="Register"
              component={Register}
              key="register"
            />
            <Route
              exact
              path="/forgot"
              name="Forgot"
              component={Forgot}
              key="forgot"
            />
            <Route
              exact
              path="/transactions/invoice"
              name="Transactions-invoice"
              component={TransactionsInvoice}
              key="transactions_invoice"
            />
          </Switch>
        ) : (
          <Dashboard />
        )}
      </div>
    </ConfigProvider>
  );
};

export default App;
