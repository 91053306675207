import "./Dashboard.css";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Menu, Layout, Radio, Alert, Button, Popconfirm } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../context/AppContextProvider";
import Routes, { ContentPages } from "../../routes";
import { Link } from "react-router-dom";
import StoreConfiguration from "../Content/StoreConfiguration/StoreConfiguration";
import Subscriptions from "../Content/Subscriptions/Subscriptions";
import { useTranslation } from "react-i18next";
import {
  updateAuthDetails,
  userLogout,
  getinvoices,
} from "../../network/network";
import { filterPagesByAddons } from "../../utils";
import Home from "../Content/Home/Home";
import {
  COMPANY_STATUS_ACTIVE,
  // COMPANY_STATUS_KYC,
  // COMPANY_STATUS_REVIEW,
  COMPANY_STATUS_BLOCKED,
  COMPANY_STATUS_SUBSCRIPTION_ENDED,
  COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW,
  COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED,
  INVOICE_STATUS_UNPAID,
  SIDEBAR_MENU_KEY_INVOICES,
  USER_TYPE_BUSINESS_OWNER,
} from "../../configs/constants";
import { useHistory } from "react-router-dom";
import Invoices from "../Content/Invoices/Invoices";

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const Dashboard = () => {
  const {
    authUser,
    activeMenu,
    setActiveMenu,
    appConfig,
    setAppConfigWrapper,
    fullScreenPOS,
  } = useContext(AppContext);
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false);
  const [showPageOnly, setShowPageOnly] = useState(false);
  const [blockRouting, setBlockRouting] = useState(false);
  const [anyUnpaidInvoice, setAnyUnpaidInvoice] = useState(false);
  const [deviceWidth, setDeviceWidth] = useState("");
  const { t } = useTranslation();
  let history = useHistory();
  const windowWidth = useRef(window.innerWidth);

  useEffect(() => {
    if (authUser.company_status === COMPANY_STATUS_ACTIVE) {
      setBlockRouting(false);
      setShowPageOnly(false);
      // } else if (
      //   authUser.company_status === COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED
      // ) {
      //   // TODO: Allow 7 days, otherwise block screen to subscription
      //   setBlockRouting(false);
      //   setShowPageOnly(false);
    } else {
      // if (authUser.company_status === COMPANY_STATUS_KYC) {
      //   setBlockRouting(true);
      //   setShowPageOnly("STORE_CONFIGURATION");
      // } else
      // if (authUser.company_status === COMPANY_STATUS_REVIEW) {
      //   setBlockRouting(true);
      //   setShowPageOnly("HOME");
      // } else
      if (authUser.company_status === COMPANY_STATUS_SUBSCRIPTION_ENDED) {
        setBlockRouting(true);
        setShowPageOnly("SUBSCRIPTION");
      } else if (
        authUser.company_status === COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW
      ) {
        setBlockRouting(true);
        setShowPageOnly("SUBSCRIPTION");
      } else if (authUser.company_status === COMPANY_STATUS_BLOCKED) {
        setBlockRouting(true);
        setShowPageOnly("INVOICES");
      } else if (
        authUser.company_status ===
        COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED
      ) {
        setShowPageOnly("INVOICES");
      }
    }

    setAppConfigWrapper(authUser.app_config);
    // eslint-disable-next-line
  }, [authUser.company_status]);

  useEffect(() => {
    if (authUser && authUser.type === USER_TYPE_BUSINESS_OWNER) {
      setTimeout(() => {
        getinvoices().then((response) => {
          let invoices = response.data.data.invoices || [];
          for (let i = 0; i < invoices.length; i++) {
            if (invoices[i].status === INVOICE_STATUS_UNPAID) {
              setAnyUnpaidInvoice(true);
              break;
            }
          }
        });
      }, 2000);
    }
  }, [authUser]);

  // useEffect(() => {
  //   if (authUser.is_company_active || showStoreConfigOnly) {
  //     setShowWaitingNotification(false);
  //   } else {
  //     setShowWaitingNotification(true);
  //   }
  // }, [showStoreConfigOnly, authUser.is_company_active]);

  useEffect(() => {
    if (
      authUser &&
      Object.keys(appConfig).length > 0 &&
      JSON.stringify(authUser.app_config) !== JSON.stringify(appConfig)
    ) {
      const formData = new FormData();
      formData.append("app_config", JSON.stringify(appConfig));
      updateAuthDetails(formData).then((res) => {
        console.log("App Config Updated");
      });
    }
  }, [appConfig, authUser]);

  useEffect(() => {
    setDeviceWidth(windowWidth.current);
  }, [windowWidth]);

  const saveCurrentPageView = (key) => {
    setActiveMenu(key);
    localStorage.setItem(
      "last_visited_page",
      JSON.stringify({
        key: key,
        path: window.location.pathname,
      })
    );
    // console.log(localStorage.getItem("last_visited_page"));
  };

  const logoutUser = () => {
    userLogout().then(function () {
      localStorage.removeItem("last_visited_page");
      localStorage.removeItem("access_token");
      window.location = "/login";
    });
  };

  return (
    <Layout id="app" hasSider={true}>
      {deviceWidth > 768 && !fullScreenPOS && (
        <Sider
          collapsible
          theme="light"
          trigger={null}
          defaultCollapsed={false}
          collapsed={isSiderCollapsed}
          collapsedWidth={window.innerWidth > 767 ? 100 : 0}
          // onCollapse={this.onCollapse}
          width={220}
          breakpoint={"md"}
          style={{
            boxShadow: "0 0 30px -10px black",
            minHeight: "100vh",
          }}
        >
          <div style={{ padding: "30px 10px", textAlign: "center" }}>
            <img src="/logo.png" alt="AnyPOS Logo" width="60%" height="auto" />
          </div>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={[activeMenu]}
            style={{
              backgroundImage: "url(images/circle-design.svg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              backgroundSize: "90%",
            }}
          >
            {filterPagesByAddons(ContentPages, authUser).map(function (
              page,
              i
            ) {
              if (page.showInSider) {
                return page.subMenus && page.subMenus.length > 0 ? (
                  <Menu.SubMenu
                    key={page.name}
                    icon={React.createElement(page.icon)}
                    title={t(page.text)}
                    disabled={blockRouting}
                  >
                    {filterPagesByAddons(page.subMenus, authUser).map(function (
                      subPage,
                      j
                    ) {
                      return (
                        <Menu.Item
                          key={subPage.name}
                          onClick={() => saveCurrentPageView(subPage.name)}
                        >
                          <Link to={subPage.path}>{t(subPage.text)}</Link>
                        </Menu.Item>
                      );
                    })}
                  </Menu.SubMenu>
                ) : (
                  <Menu.Item
                    key={page.name}
                    icon={React.createElement(page.icon)}
                    onClick={() => saveCurrentPageView(page.name)}
                    disabled={blockRouting}
                  >
                    <Link to={page.path}>{t(page.text)}</Link>
                  </Menu.Item>
                );
              }
              return false;
            })}
          </Menu>
        </Sider>
      )}
      <Layout className="site-layout">
        <>
          {!fullScreenPOS && (
            <>
              <Header
                className="site-layout-background"
                style={{
                  padding: "0 10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Menu
                  theme="light"
                  mode="horizontal"
                  style={{
                    borderBottom: "none",
                    minWidth: "300px",
                  }}
                >
                  {deviceWidth > 768 && (
                    <Menu.Item key="sidebar-collapse-menu-item">
                      {isSiderCollapsed ? (
                        <MenuUnfoldOutlined
                          onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
                        />
                      ) : (
                        <MenuFoldOutlined
                          onClick={() => setIsSiderCollapsed(!isSiderCollapsed)}
                        />
                      )}
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="top-navbar-0"
                    style={{
                      borderBottom: "none",
                    }}
                  >
                    <Radio.Group
                      value={appConfig.direction}
                      onChange={(e) => {
                        setAppConfigWrapper({ direction: e.target.value });
                      }}
                      buttonStyle="solid"
                    >
                      <Radio.Button value="ltr">English</Radio.Button>
                      <Radio.Button value="rtl">عربي</Radio.Button>
                    </Radio.Group>
                  </Menu.Item>
                </Menu>
                {authUser.business_name && <h3>{authUser.business_name}</h3>}
                {deviceWidth > 768 ? (
                  <Menu
                    theme="light"
                    mode="horizontal"
                    style={{
                      borderBottom: "none",
                      display: "block",
                      minWidth: "300px",
                      textAlign:
                        appConfig.direction === "rtl" ? "left" : "right",
                    }}
                  >
                    {/* <Menu.Item key="top-navbar-1">
              <MailOutlined />
            </Menu.Item>
            <Menu.Item key="top-navbar-2">
              <BellOutlined />
            </Menu.Item> */}
                    <SubMenu
                      key="top-navbar-3"
                      title={
                        <span
                          className="submenu-title-wrapper"
                          style={{ width: "150px" }}
                        >
                          {authUser.name + " "}
                          <DownOutlined />
                        </span>
                      }
                    >
                      <Menu.Item key="31" onClick={logoutUser}>
                        {t("logout")}
                      </Menu.Item>
                    </SubMenu>
                  </Menu>
                ) : (
                  <div style={{ padding: "0 10px" }}>
                    <Popconfirm
                      title={t("warning_logout")}
                      onConfirm={() => logoutUser()}
                      okText={t("yes")}
                      cancelText={t("no")}
                    >
                      <LogoutOutlined style={{ fontSize: "18px" }} />
                    </Popconfirm>
                  </div>
                )}
              </Header>
              {anyUnpaidInvoice && (
                <Alert
                  message={t("unpaid_invoice")}
                  description={t("pay_your_invoice")}
                  type="error"
                  showIcon
                  action={
                    <Button
                      size="small"
                      danger
                      onClick={() => {
                        history.push("/invoices");
                        setActiveMenu(SIDEBAR_MENU_KEY_INVOICES);
                      }}
                    >
                      {t("show_invoices")}
                    </Button>
                  }
                />
              )}
            </>
          )}
        </>

        <Content
          className="site-layout-background"
          style={{
            margin: "24px 16px",
            padding: "0",
            background: "transparent",
            minHeight: "80vh",
          }}
        >
          {blockRouting ? (
            <>
              {showPageOnly === "STORE_CONFIGURATION" && <StoreConfiguration />}
              {showPageOnly === "HOME" && <Home />}
              {showPageOnly === "SUBSCRIPTION" && <Subscriptions />}
              {showPageOnly === "INVOICES" && <Invoices />}
            </>
          ) : (
            <Routes />
          )}
        </Content>
        {!fullScreenPOS && (
          <Footer
            style={{
              textAlign: "center",
              backgroundColor: "#ffffff",
              fontWeight: "500",
              padding: "10px",
            }}
          >
            AnyPOS © {new Date().getFullYear()}
          </Footer>
        )}
      </Layout>
    </Layout>
  );
};

export default Dashboard;
