import "../AuthStyle.css";
import { Link, useHistory } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import {
  forgotPassword,
  resetPassword,
  verifyForgotOtp,
} from "../../../network/network";
import { Form, Input, Button, message, Col, Row, Radio } from "antd";

import { useTranslation } from "react-i18next";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Forgot = () => {
  const { appConfig, setAppConfigWrapper } = useContext(AppContext);
  const [OTPFormVisible, setOTPFormVisible] = useState(false);
  const [resetFormVisible, setResetFormVisible] = useState(false);
  const [token, setToken] = useState(false);
  const [loading, setloading] = useState(false);

  const [forgotForm] = Form.useForm();
  const { t } = useTranslation();
  let history = useHistory();

  const handleForgotValidation = () => {
    setloading(true);
    forgotForm.validateFields().then((values) => {
      forgotPassword(values) // OTP SMS sent as well
        .then((response) => {
          setloading(false);
          if (response.data && response.data.success) {
            setOTPFormVisible(true);
          } else {
            let error = response.response;
            if (error.status === 400) {
              let errorMessages = Object.values(error.data.message);
              NotificationWithIcon("error", errorMessages);
            } else {
              let errorMessages = Object.values(error.data.message);
              errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const onOTPFormFinish = (otpValues) => {
    setloading(true);
    forgotForm.validateFields().then((values) => {
      verifyForgotOtp({
        ...otpValues,
        ...values,
      })
        .then((response) => {
          setloading(false);
          if (response.data && response.data.success) {
            setToken(response.data.token);
            setResetFormVisible(true);
          } else {
            let error = response.response;
            if (error.status === 400) {
              let errorMessages = Object.values(error.data.message);
              NotificationWithIcon("error", errorMessages);
            }
            if (error.status === 500) {
              let errorMessages = Object.values(error.data.message);
              errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const onResetPassword = (resetPassValues) => {
    setloading(true);
    forgotForm.validateFields().then((values) => {
      resetPassword({
        ...resetPassValues,
        ...values,
        token,
      })
        .then((response) => {
          setloading(false);
          if (response.data && response.data.success) {
            message.success(t("reset_password_successfully"));
            history.push("/login");
          } else {
            let error = response.response;
            if (error.status === 400) {
              let errorMessages = Object.values(error.data.message);
              NotificationWithIcon("error", errorMessages);
            } else {
              let errorMessages = Object.values(error.data.message);
              errorMessages.forEach(function (key, value) {
                let temp = Object.values(key);
                message.error(temp[0]);
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  return (
    <>
      <div className="authSection">
        <div className="lang_button">
          <Radio.Group
            value={appConfig.direction}
            onChange={(e) => {
              setAppConfigWrapper({ direction: e.target.value });
            }}
            buttonStyle="solid"
            defaultValue="ltr"
          >
            <Radio.Button value="ltr">English</Radio.Button>
            <Radio.Button value="rtl">عربي</Radio.Button>
          </Radio.Group>
        </div>
        <div style={{ width: "100%" }}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={8}
              xl={8}
              xxl={8}
              className="authSection-left"
            >
              <img src="/images/pos-on-any-device.jpg" alt="AnyPOS Logo" />

              <div>
                <p>{t("already_have_an_account")}</p>
                <Button>
                  <Link to="/login">{t("login")}</Link>
                </Button>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={18}
              lg={16}
              xl={16}
              xxl={16}
              className="authSection-right"
            >
              <Form
                name="forgot-user-form"
                layout="vertical"
                onFinish={handleForgotValidation}
                form={forgotForm}
                style={{
                  maxWidth: "300px",
                  Width: "100%",
                  margin: "20px auto 0",
                  display:
                    !OTPFormVisible && !resetFormVisible ? "block" : "none",
                }}
              >
                <h2 className="authSection-heading">{t("forgot")}</h2>
                <Form.Item
                  name="phone"
                  label={t("phone_number") + " eg: +966 5xxxxxxx"}
                  rules={[
                    {
                      required: true,
                      message: t("validate_phone_required"),
                    },
                    {
                      min: 12,
                      message: t("validate_phone_exact_limit"),
                    },
                    {
                      max: 12,
                      message: t("validate_phone_exact_limit"),
                    },
                  ]}
                >
                  <PhoneInput
                    localization={["sa"]}
                    country={"sa"}
                    onlyCountries={["sa"]}
                    masks={{ sa: "(...) ..-..-.." }}
                    placeholder="+966 (123) 45-67-89"
                    style={{
                      width: "100%",
                      direction: "ltr",
                    }}
                  />
                </Form.Item>

                <Form.Item style={{ margin: "20px 0" }}>
                  <Button
                    size="large"
                    block
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    {t("send_otp")}
                  </Button>
                </Form.Item>
              </Form>

              <Form
                name="validate-otp-form"
                layout="vertical"
                onFinish={onOTPFormFinish}
                style={{
                  width: "300px",
                  margin: "20px auto 0",
                  display:
                    OTPFormVisible && !resetFormVisible ? "block" : "none",
                }}
              >
                <h2 className="authSection-heading">{t("verify_otp")}</h2>
                <p>
                  {t("opt_message")}
                  <br />
                  {t("if_you_want_to_change_phone_number")}{" "}
                  <Link to="#" onClick={() => setOTPFormVisible(false)}>
                    {t("click_here")}
                  </Link>
                </p>
                <Form.Item
                  name="otp"
                  label="OTP"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      max: 6,
                    },
                    {
                      min: 6,
                    },
                  ]}
                  hasFeedback
                >
                  <Input type="number" minLength={6} maxLength={6} />
                </Form.Item>

                <Form.Item style={{ margin: "20px 0" }}>
                  <Button
                    block
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                  >
                    {t("validate")}
                  </Button>
                </Form.Item>
              </Form>

              <Form
                name="validate-otp-form"
                layout="vertical"
                onFinish={onResetPassword}
                style={{
                  width: "300px",
                  margin: "20px auto 0",
                  display: resetFormVisible ? "block" : "none",
                }}
              >
                <h2 className="authSection-heading">{t("reset_password")}</h2>

                <Form.Item
                  name="password"
                  label={t("password")}
                  rules={[
                    {
                      required: true,
                      message: t("validate_password_required"),
                    },
                    {
                      min: 6,
                      message: t("validate_password_min_characters"),
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="password_confirmation"
                  label={t("confirm_password")}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t("validate_password_confirm_not_matched"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("validate_password_confirm_not_matched"))
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item style={{ margin: "20px 0" }}>
                  <Button
                    block
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                  >
                    {t("reset")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Forgot;
