// Notification Types
export const NOTIFICATION_TYPE_INFO = 1;
export const NOTIFICATION_TYPE_SUCCESS = 2;
export const NOTIFICATION_TYPE_WARNING = 3;
export const NOTIFICATION_TYPE_ERROR = 4;

// TRANSACTION_TYPES
export const TRANSACTION_TYPE_CASH = 1;
export const TRANSACTION_TYPE_MADA = 2;
export const TRANSACTION_TYPE_STC = 3;
export const TRANSACTION_TYPE_CREDIT = 4;
export const TRANSACTION_TYPE_MULTI = 5;

export const transactionTypes = [
  { id: 1, text: "Cash" },
  // { id: 2, text: "MADA" },
  // { id: 3, text: "STC Pay" },
  { id: 4, text: "Card" },
  { id: 5, text: "Multi Payment" },
];

export const TAX_PERCENTAGE = 0.15;

// SUBSCRIPTION_PLANS
export const SUBSCRIPTION_PLAN_MONTHLY = 1;
export const SUBSCRIPTION_PLAN_QUARTERLY = 2;
export const SUBSCRIPTION_PLAN_YEARLY = 3;

// SUBSCRIPTION_PRICES
export const SUBSCRIPTION_CHARGES_MONTHLY = 50;
export const SUBSCRIPTION_CHARGES_YEARLY = 500;

// REFUND_TYPE
export const REFUND_TYPE_FULL = 1;
export const REFUND_TYPE_PARTIAL = 2;

// User Types
export const USER_TYPE_ADMIN = 1;
export const USER_TYPE_BUSINESS_OWNER = 2;
export const USER_TYPE_EMPLOYEE = 3;

// Plan Types
export const PLAN_TYPE_BASIC = 1;
export const PLAN_TYPE_PRO = 2;

// Plan Monthly
export const PERIOD_MONTHLY = 1;
export const PERIOD_YEARLY = 2;

// Company Status
export const COMPANY_STATUS_ACTIVE = 1;
export const COMPANY_STATUS_KYC = 2;
export const COMPANY_STATUS_REVIEW = 3;
export const COMPANY_STATUS_SUBSCRIPTION_ENDED = 4;
export const COMPANY_STATUS_SUBSCRIPTION_IN_REVIEW = 5;
export const COMPANY_STATUS_SUBSCRIPTION_INVOICE_GENERATED = 6;
export const COMPANY_STATUS_BLOCKED = 99;

// Invoice Status
export const INVOICE_STATUS_UNPAID = 0;
export const INVOICE_STATUS_CANCELLED = 1;
export const INVOICE_STATUS_PAID = 2;
export const INVOICE_STATUS_REFUNDED = 3;

// Payment Brand
export const PAYMENT_BRAND_VISA = 1;
export const PAYMENT_BRAND_MASTER = 2;
export const PAYMENT_BRAND_MADA = 3;

// Invoice Detail Type
export const INVOICE_DETAIL_TYPE_SUBSCRIPTION = 1;
export const INVOICE_DETAIL_TYPE_LICENSE = 2;
export const INVOICE_DETAIL_TYPE_DISCOUNT = 3;
export const INVOICE_DETAIL_TYPE_TAX = 4;
export const INVOICE_DETAIL_TYPE_DEVICE_PAYMENT = 5;

// Invoice Type
export const INVOICE_TYPE_SUBSCRIPTION = 1;
export const INVOICE_TYPE_LICENSE = 2;

// Menu Keys
export const SIDEBAR_MENU_KEY_HOME = "home";
export const SIDEBAR_MENU_KEY_INVOICES = "invoices";
export const SIDEBAR_MENU_KEY_STORE_CONFIGURATION = "store-configuration";

// integration_type
export const ODOO = "Odoo";
export const XERO = "Xero";
export const ZOHO = "Zoho";
